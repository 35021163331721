import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { search } from "./search/reducers";
import { company } from "./company/reducers";
import { person } from "./person/reducers";
import { filter } from "./filter/reducers";
import { map } from "./map/reducers";
import { sector } from "./sector/reducers";
import { globalRequest } from "./globalRequest/reducers";

export const rootReducer = {
  search,
  company,
  globalRequest,
  map,
  person,
  filter,
  sector
};
const devTool =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__()
    : compose;

const store = createStore(
  combineReducers(rootReducer),
  compose(
    applyMiddleware(thunkMiddleware),
    devTool
  )
);

export default store;
