import customeHtmlMarker from "./customeMarker";
let previousWindow = undefined;
let mapUtils = {
  initialiseMap: (Element, zoomLevel, coords, showUI) => {
    let mapInstance = new window.google.maps.Map(Element, {
      zoom: zoomLevel,
      center: coords,
      disableDefaultUI: showUI,
      styles: [
        { elementType: "geometry", stylers: [{ color: "#d9efff" }] },
        // { elementType: "labels.text.stroke", stylers: [{ color: "#d9efff" }] },
        // { elementType: "labels.text.fill", stylers: [{ color: "#d9efff" }] },
        // {
        //   featureType: "administrative.locality",
        //   elementType: "labels.text.fill",
        //   stylers: [{ color: "#d9efff" }]
        // },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d9efff" }]
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [{ color: "#d9efff" }]
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d9efff" }]
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ color: "#d9efff" }]
        },
        {
          featureType: "road",
          elementType: "geometry.stroke",
          stylers: [{ color: "#d9efff" }]
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d9efff" }]
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [{ color: "#d9efff" }]
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [{ color: "#d9efff" }]
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d9efff" }]
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [{ color: "#d9efff" }]
        },
        {
          featureType: "transit.station",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d9efff" }]
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [{ color: "#ffffff" }]
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d9efff" }]
        },
        {
          featureType: "water",
          elementType: "labels.text.stroke",
          stylers: [{ color: "#d9efff" }]
        }
      ]
    });
    return mapInstance;
  },
  createMarker: (locDetail, mapInstance, onClick, label) => {
    let marker = customeHtmlMarker({
      latlng: new window.google.maps.LatLng(
        locDetail.latitude,
        locDetail.longitude
      ),
      map: mapInstance,
      html: mapUtils.handleMarkerHtml(locDetail)
    });
    marker.addListener("click", () => {
      onClick(locDetail);
    });
    return marker;
  },
  handleMarkerHtml: locDetail => {
    if (locDetail.gccAlert === "Yes") {
      return (
        '<div class="custom-marker-icon bg-hero font-fmedium cursor-pointer relative">' +
        '<div class="h-6 w-6 -mr-2 -mt-2 rounded-full flex justify-center items-center absolute pin-t pin-r bg-notify border-2 border-white"><i class="fa fa-bell"></i></div>' +
        '<div class="cursor-pointer text-on-marker">' +
        locDetail.companyCount
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        "</div></div>"
      );
    } else {
      return (
        '<div class="custom-marker-icon bg-hero font-fmedium cursor-pointer relative">' +
        '<div class="cursor-pointer text-on-marker">' +
        locDetail.companyCount
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        "</div></div>"
      );
    }
  },
  createCluster: (mapInstance, markers) => {
    new window.MarkerClusterer(mapInstance, markers, {
      imagePath:
        "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"
    });
  },
  fitBounds(mapInstance, Array) {
    let bounds = new window.google.maps.LatLngBounds();
    for (let i = 0; i < Array.length; i++) {
      bounds.extend(new window.google.maps.LatLng(Array[i][0], Array[i][1]));
    }
    mapInstance.setCenter(bounds.getCenter());
    mapInstance.fitBounds(bounds);
  },
  clearMarker(mapInstance, markerInstance) {
    markerInstance.setMap(null);
  },
  moveToLocation(mapInstance, lat, lng) {
    var center = new window.google.maps.LatLng(lat, lng);
    mapInstance.panTo(center);
  },
  zoomTo(mapInstance, zoomLevel) {
    mapInstance.setZoom(zoomLevel);
  },
  addPopupOnMarker(markerInstance, Content, mapInstance) {
    let infowindow = new window.google.maps.InfoWindow({
      content: Content
      // pixelOffset: new window.google.maps.Size(250, 300)
    });
    markerInstance.addListener("mouseover", function () {
      if (previousWindow !== undefined) {
        previousWindow.close();
        previousWindow = undefined;
      }
      infowindow.open(mapInstance, markerInstance);
      previousWindow = infowindow;
    });
  }
};
// open source map........................................................................
// let mapUtils = {
//     initialiseMap: (Element, zoomLevel, coords, showUI) => {
//         let mapInstance = window.L.map(Element).setView([coords.lat, coords.lng], zoomLevel);
//         let tileUrl = 'http://{s}.tile.osm.org/{z}/{x}/{y}.png';
//         window.L.tileLayer(
//             tileUrl,
//             { attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' }
//         )
//             .addTo(mapInstance);
//         mapInstance.removeControl(mapInstance.zoomControl);
//         return mapInstance;
//         // return new window.google.maps.Map(Element, {
//         //     zoom: zoomLevel,
//         //     center: coords,
//         //     disableDefaultUI: showUI
//         // });
//     },
//     createMarker: (locDetail, mapInstance, onClick, label) => {
//         var marker = window.L.marker([locDetail.latitude, locDetail.longitude], {
//             icon: new window.L.DivIcon({
//                 className: 'my-div-icon',
//                 html: '<div class="custome-marker-icon"><div class="text-on-marker">' + locDetail.companyCount + '</div></div>'
//             })
//         }).on('click', () => {
//             onClick(locDetail);
//         }).addTo(mapInstance);
//         return marker;
//     },
//     createCluster: (mapInstance, markers) => {
//         new window.MarkerClusterer(mapInstance, markers,
//             { imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' });
//     },
//     fitBounds(mapInstance, Array) {
//         mapInstance.fitBounds(Array);
//     },
//     clearMarker(mapInstance, markerInstance) {
//         mapInstance.removeLayer(markerInstance);
//     },
//     addPopupOnMarker(markerInstance, Content) {
//         markerInstance.bindPopup(Content);
//         markerInstance.on('mouseover', function (event) {
//             markerInstance.openPopup();
//         });
//         // markerInstance.on('mouseout', function (event) {
//         //     markerInstance.closePopup();
//         // });
//     }
// }
export default mapUtils;
