import React, { Component } from "react";
import "./acquiredCompany.scss";
import Tab from "components/common/tab";
import Overview from "components/common/overview";
import { ImageBox, InfoLabel } from "components/common/elementBox";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as companyActions from "store/company/actions";
import searchMethod from "utils/searchMethods";
import ReactPaginate from "react-paginate";
import DataTable from "../common/tabular/tabular";
import DownloadExcel from "../../utils/jsonToExcel";
import hardCodedUtils from "../../utils/hardcodedUtils";
class acquiredCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyData: null,
      currentTab: 1,
      companyTableData: null,
      inputVal: "",
      pageCount: 0,
      rows: [],
      filteredRows: [],
      showDropDown: false,
      recievedState: props.location.state,
      tabData: [
        { name: "Industry Segment", id: 1, url: 1 },
        { name: "GCC presence", id: "GCC", url: 12 }, // self created tab id ......
        { name: "Funding Rounds", id: 2, url: 2 },
        { name: "M&A (As Acquirer)", id: 3, url: 3 },
        { name: "M&A (As Target)", id: 4, url: 4 },
        { name: "Competitors", id: 12, url: 5 },
        { name: "Strategic Gaps", id: 13, url: 6 },
        { name: "Potential Acquirers", id: 14, url: 7 },
        { name: "Team", id: 5, url: 8 },
        { name: "Signal", id: 6, url: 9 },
        { name: "Investments", id: 7, url: 10 },
        { name: "Partners", id: 8, url: 11 },
        // { name: "Patents", id: 10, url: 13 }
      ],
      gccOptions: [
        "Signals",
        "Acquisitions",
        "Offices",
        "Partnerships",
        "Affiliations"
      ],
      selectedValue: "Signals",
      table: {
        rows: [],
        columns: [
          "Company",
          "Sector",
          "Industry",
          "Sub-Industry",
          "Sub- Industry Definition",
          "Sub- Industry Maturity",
          "Sub- Industry Funding Momentum",
          "Sub- Industry Acquisition Momentum"
        ]
      }
    };
  }
  // life cycle methods...................................................................
  componentWillMount() {
    document.addEventListener("mousedown", this.handleInsideClick, false);
  }
  componentWillUnmount() {
    this.props.unsetAllData();
    document.removeEventListener("mousedown", this.handleInsideClick, false);
  }
  handleInsideClick = e => {
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.handleOutsideClick();
  };
  handleOutsideClick = () => {
    this.setState({
      showDropDown: false
    });
  };
  componentDidMount() {
    this.handleComponentMountApi(this.props);
  }
  componentWillReceiveProps(props) {
    if (this.state.recievedState !== null) {
      if (
        this.state.recievedState.Company[0] !==
        props.location.state.Company[0] ||
        this.state.recievedState.CompanyPermalink[0] !==
        props.location.state.CompanyPermalink[0]
      ) {
        this.handleComponentMountApi(props);
        this.setState({ recievedState: props.location.state, currentTab: 1 });
      }
    }
    if (props.companyDetail !== null) {
      this.setState({ companyData: props.companyDetail }, () => {
        this.setState({
          social: {
            facebook:
              this.state.companyData.Facebook &&
                this.state.companyData.Facebook.val
                ? this.state.companyData.Facebook.val
                : null,
            twitter:
              this.state.companyData.Twitter &&
                this.state.companyData.Twitter.val
                ? this.state.companyData.Twitter.val
                : null,
            linkedin:
              this.state.companyData.LinkedIn &&
                this.state.companyData.LinkedIn.val
                ? this.state.companyData.LinkedIn.val
                : null,
            google_plus:
              this.state.companyData.GooglePlus &&
                this.state.companyData.GooglePlus.val
                ? this.state.companyData.GooglePlus.val
                : null,
            website:
              this.state.companyData.HomePageURL &&
                this.state.companyData.HomePageURL.val
                ? this.state.companyData.HomePageURL.val
                : null,
            crunchBase:
              this.state.companyData["CB"] && this.state.companyData["CB"].val
                ? this.state.companyData["CB"].val
                : null
          }
        });
      });
    }
    if (
      props.companyTabData !== null &&
      props.companyTabData !== undefined &&
      props.companyTabData !== ""
    ) {
      this.setState({
        companyTableData: props.companyTabData,
        pageCount: props.companyTabData.rows.length / 50,
        rows: props.companyTabData.rows.slice(0, 50),
        filteredRows: props.companyTabData.rows.slice(0, 50)
      });
    } else {
      this.setState({
        companyTableData: { rows: [], columns: [] },
        rows: []
      });
    }
  }
  //method to be call when components get mounted.....................................
  handleComponentMountApi(props) {
    this.props.unsetAllData();
    Promise.all([
      props.requestGetCompanyDetailById({
        id: 57,
        selectedValues: props.location.state
      }),
      props.requestGetTabularDataOfCompany({
        entityType: "Private",
        tabHeading: "1",
        companyName: props.location.state.Company[0],
        companyPermalink: props.location.state.CompanyPermalink[0]
      })
    ]);
  }
  //function to handle when a company col is clicked...............................
  handlePersonLink = data => {
    let state = {
      Person: [data.data.Person],
      PersonPermalink: [data.data.PersonPermalink]
    };
    this.props.history.push("/person", state);
  };
  handleCompanyLink = data => {
    let state = {}
    if (data.column.colDef.headerName == "Company" && data.data.Company && data.data.CompanyPermalink && data.data.CompanyType) {
      state = {
        Company: [data.data.Company],
        CompanyPermalink: [data.data.CompanyPermalink]
      };
      this.props.history.push(
        "/company/" + data.data.CompanyType.toLowerCase(),
        state
      );
    } else if (data.column.colDef.headerName == "Target" && data.data.Target && data.data.TargetCompanyType && data.data.TargetPermalink) {
      state = {
        Company: [data.data.Target],
        CompanyPermalink: [data.data.TargetPermalink]
      };
      this.props.history.push(
        "/company/" + data.data.TargetCompanyType.toLowerCase(),
        state
      );
    }
    else if (data.column.colDef.headerName == "Acquirer" && data.data.Acquirer && data.data.AcquirerCompanyType && data.data.AcquirerPermalink) {
      state = {
        Company: [data.data.Acquirer],
        CompanyPermalink: [data.data.AcquirerPermalink]
      };
      this.props.history.push(
        "/company/" + data.data.AcquirerCompanyType.toLowerCase(),
        state
      );
    } else if (data.column.colDef.headerName == "Competitor" && data.data.Competitor && data.data.CompetitorCompanyType && data.data.CompetitorPermalink) {
      state = {
        Company: [data.data.Competitor],
        CompanyPermalink: [data.data.CompetitorPermalink]
      };
      this.props.history.push(
        "/company/" + data.data.CompetitorCompanyType.toLowerCase(),
        state
      );
    }
  };
  //create cols for tabularData .........................................................
  handleConstructionofColumns = cols => {
    let colList = [];
    if (Array.isArray(cols)) {
      colList = cols.map((value, index) => {
        let colObj = { headerName: value.replace(/_/g, " "), field: value };
        if (value == "Person") {
          colObj.onCellClicked = this.handlePersonLink;
          colObj.cellClass = "text-hero";
        } else if (value == "Company" || value == "Target" || value == "Acquirer" || value == "Competitor") {
          colObj.onCellClicked = this.handleCompanyLink;
          colObj.cellClass = "text-hero";
        } else if (value == "Title" || value == "title") {
          colObj.onCellClicked = this.handleTitleClick;
          colObj.cellClass = "text-hero";
        }
        return colObj;
      });
    } else {
      for (let keyName in cols) {
        let colObj = { headerName: cols[keyName], field: keyName };
        if (keyName == "Person") {
          colObj.onCellClicked = this.handlePersonLink;
          colObj.cellClass = "text-hero";
        } else if (keyName == "Company" || keyName == "Target" || keyName == "Acquirer" || keyName == "Competitor") {
          colObj.onCellClicked = this.handleCompanyLink;
          colObj.cellClass = "text-hero";
        } else if (keyName == "Title" || keyName == "title") {
          colObj.onCellClicked = this.handleTitleClick;
          colObj.cellClass = "text-hero";
        }
        colList.push(colObj);
      }
    }
    return searchMethod.removeItemsFromArray(
      hardCodedUtils.absractTabItems,
      colList
    )
  };
  //function to handle the tabs based on the user selection.................................
  handleSelectedTab = index => {
    this.setState(
      prevState => ({
        currentTab: prevState.tabData[index].url,
        inputVal: "",
        rows: []
      }),
      () => {
        if (this.state.tabData[index].id === 6) {
          this.props.requestGetCompanySignalsData({
            permalink: this.props.location.state.CompanyPermalink,
            type: ["Signal"]
          });
        } else if (this.state.tabData[index].id === "GCC") {
          this.props.requestGetCompanyGccSignalsData({
            permalink: this.props.location.state.CompanyPermalink
          });
        } else if (this.state.tabData[index].id === 10) {
          this.props.requestGetCompanyPatentsData({
            permalink: this.props.location.state.CompanyPermalink,
            type: ["Patents"]
          });
        } else {
          this.props.requestGetTabularDataOfCompany({
            entityType: "Private",
            tabHeading: this.state.tabData[index].id,
            companyName: this.props.location.state.Company[0],
            companyPermalink: this.props.location.state.CompanyPermalink[0]
          });
        }
      }
    );
  };
  //function to reder the table based on the selection of the tab.....................
  // handleTableRendering = index => {
  //   if (this.state.tabData[index].id === 6) {
  //     return (
  //       <Table
  //         rows={this.state.companyTableData.rows}
  //         columns={this.state.companyTableData.columns}
  //         withKey={true}
  //       />
  //     );
  //   } else if (this.state.tabData[index].id === 10) {
  //     return (
  //       <Table
  //         rows={this.state.companyTableData.rows}
  //         columns={this.state.companyTableData.columns}
  //         withKey={false}
  //       />
  //     );
  //   } else {
  //     return (
  //       <Table
  //         rows={this.state.companyTableData.rows}
  //         columns={this.state.companyTableData.columns}
  //         withKey={true}
  //       />
  //     );
  //   }
  // };
  //function to handle the back button ....................................................
  handleBackClick = () => {
    this.props.history.goBack();
  };
  //function to handle the page click......................................................
  handlePageClick = val => {
    let start,
      end = 0;
    if (val.selected == 0) {
      start = 0;
      end = 50;
    } else {
      start = 50 * val.selected;
      end = 50 * val.selected + 50;
    }
    let rows = this.state.companyTableData.rows.slice(start, end);
    this.setState({ inputVal: "", rows, filteredRows: rows });
  };
  //function to handle the formating of a number greater than 3 digit............................
  handleNumberFormatting = num => {
    if (num) return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else return "-";
  };
  //function to handle the input search ......................................................
  handleInputSearch = text => {
    if (text === "") {
      this.setState({
        companyTableData: this.props.companyTabData,
        filteredRows: this.state.rows,
        inputVal: text
      });
    } else {
      let filteredRows = searchMethod.filterArrayWithKey(
        text,
        this.state.rows
      );
      this.setState({
        companyTableData: this.props.companyTabData,
        filteredRows,
        inputVal: text
      });
    }
  };
  //function to convert current data into excel...........................................
  handlePrint = value => {
    if (!this.state.rows.length) {
      alert("Nothing to download");
    } else {
      let excelRows = [];
      for (let i = 0; i < this.state.rows.length; i++) {
        let excelObject = {};
        for (let j = 0; j < this.state.companyTableData.columns.length; j++) {
          Object.defineProperty(
            excelObject,
            this.state.companyTableData.columns[j],
            {
              value: this.state.rowsList[i][
                this.state.companyTableData.columns[j]
              ],
              writable: true,
              configurable: true,
              enumerable: true
            }
          );
        }
        excelRows.push(excelObject);
      }
      DownloadExcel(excelRows, "CompanyList", true);
    }
  };
  //function to handle the gcc dropdown............................................
  handleDropDown = () => {
    this.setState({
      showDropDown: !this.state.showDropDown
    });
  };
  handleGCCDropDown = value => {
    this.setState({
      selectedValue: value
    });
    switch (value) {
      case "Signals": {
        this.props.requestGetCompanyGccSignalsData({
          permalink: this.props.location.state.CompanyPermalink
        });
        break;
      }
      case "Acquisitions": {
        this.props.requestGetCompanyGccAquisationData({
          permalink: this.props.location.state.CompanyPermalink
        });
        break;
      }
      case "Offices": {
        this.props.requestGetCompanyGccOfficesData({
          permalink: this.props.location.state.CompanyPermalink
        });
        break;
      }
      case "Partnerships": {
        this.props.requestGetCompanyGccPartnershipsData({
          permalink: this.props.location.state.CompanyPermalink
        });
        break;
      }
      case "Affiliations": {
        this.props.requestGetCompanyGccAffiliationsData({
          permalink: this.props.location.state.CompanyPermalink
        });
        break;
      }
    }
    this.handleDropDown();
  };
  //function to handle when link is clicked,...............................................
  handleTitleClick = (obj) => {
    window.open(obj.data.url)
  }
  // render function to render the component.................................................
  render() {
    const state = this.state;
    const props = this.props;
    return (
      <div className="component-acquired-company view">
        <div className="overview">
          <div className="overview-header flex justify-between items-center mb-8">
            <div
              className="text-xs cursor-pointer"
              onClick={() => this.handleBackClick()}
            >
              <i className="fa fa-caret-left" /> BACK
            </div>
            <div>
              {state.companyData && (
                <div className="contact-info-container">
                  <InfoLabel
                    icon={"fa fa-map-marker-alt mr-1 text-xs"}
                    value={state.companyData.Headquarter.val}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="profile-info-container">
            <div className="profile-logo-container">
              {this.state.companyData !== null ? (
                <ImageBox
                  image={this.state.companyData["Logo URL"].val}
                  height={85}
                  width={100}
                />
              ) : null}
            </div>
            <div className="profile-detail-container">
              <div className="company-name">
                {this.props.location.state.Company[0]}
              </div>
              <div className="company-info">
                <table className="info-table">
                  <thead>
                    {state.companyData !== null ? (
                      <tr>
                        <th>
                          {state.companyData.Founded &&
                            state.companyData.Founded.val
                            ? state.companyData.Founded.val
                            : "-"}
                        </th>
                        <th>
                          {state.companyData.Employees &&
                            state.companyData.Employees.val
                            ? this.handleNumberFormatting(
                              state.companyData.Employees.val
                            )
                            : "-"}
                        </th>
                        <th>
                          {state.companyData.CompanyStage &&
                            state.companyData.CompanyStage.val
                            ? state.companyData.CompanyStage.val
                            : "-"}
                        </th>
                        <th>
                          {state.companyData.Acquired &&
                            state.companyData.Acquired.val
                            ? state.companyData.Acquired.val
                            : "-"}
                        </th>
                        <th>
                          {state.companyData.Acquirer &&
                            state.companyData.Acquirer.val
                            ? state.companyData.Acquirer.val
                            : "-"}
                        </th>
                        <th>
                          {state.companyData["GCC Momentum"] &&
                            state.companyData["GCC Momentum"].val
                            ? state.companyData["GCC Momentum"].val
                            : "-"}
                        </th>
                      </tr>
                    ) : null}
                  </thead>
                  <tbody>
                    <tr>
                      <td>Founded </td>
                      <td>Employees </td>
                      <td>Company Stage </td>
                      <td>Acquired </td>
                      <td>Acquirer </td>
                      <td>GCC Momentum </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {state.companyData !== null ? (
            <Overview
              overview={state.companyData.LongDescription.val}
              social={state.social}
            />
          ) : null}
        </div>
        <div className="sticky-container">
          <Tab
            handleButtonClick={value => this.handleSelectedTab(value)}
            tabList={state.tabData}
            activeTab={state.currentTab}
          />

          {props.location.pathname === "/company/acquired" ? (
            state.companyTableData ? (
              <div className="mt-4">
                <input
                  className="profile-search-bar"
                  value={this.state.inputVal}
                  placeholder="Search here"
                  type="text"
                  onChange={e => this.handleInputSearch(e.target.value)}
                />
                {/* <button className="square-btn ml-2 mr-24" onClick={this.handlePrint}>
                  <i className="fas fa-file-download" />
                </button> */}
                {/* select box for gcc only triggers when gcc tab is selectes.............. */}
                {this.state.currentTab === 12 ? (
                  <div
                    ref={node => (this.node = node)}
                    className="basic-dropdown"
                  >
                    <button
                      className="dropbtn flex-between"
                      onClick={() => this.handleDropDown()}
                    >
                      {state.selectedValue}
                      <i className="absolute px-3 pin-r fa fa-chevron-down" />
                    </button>
                    <div
                      className={
                        this.state.showDropDown
                          ? "dropdown-content active"
                          : "dropdown-content"
                      }
                    >
                      {this.state.gccOptions.map((value, index) => (
                        <div
                          key={index}
                          className={
                            this.state.selectedValue === value
                              ? "list active"
                              : "list"
                          }
                          onClick={() => this.handleGCCDropDown(value)}
                        >
                          {value}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
                <DataTable
                  rows={state.filteredRows}
                  columns={this.handleConstructionofColumns(
                    state.companyTableData.columns
                  )}
                  height={"600px"}
                />
              </div>
            ) : null
          ) : null}
        </div>
        {this.state.rows.length && this.state.companyTableData && this.state.companyTableData.rows.length > 50 ? (
          <div className="company-paginate">
            <ReactPaginate
              previousLabel={<i className="fa fa-chevron-left" />}
              nextLabel={<i className="fa fa-chevron-right" />}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        ) : null}
      </div>
    );
  }
}
export default connect(
  ({ company }) => ({ ...company }),
  dispatch => bindActionCreators({ ...companyActions }, dispatch)
)(acquiredCompany);
