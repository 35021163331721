import React, { Component } from "react";
import "./filter.scss";
export default class filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterData: []
    };
  }
  //life cycle methods....................................................................
  componentDidMount() {
    this.setState({
      filterData: this.props.filterData
    });
  }
  componentWillReceiveProps(props) {
    this.setState({
      filterData: props.filterData
    });
  }
  render() {
    return (
      <div className="component-filter rounded overflow-hidden bg-white w-full relative">
        <div className="relative header-container flex-between border-b border-rowBorder px-4 py-3 text-sm absolute bg-white w-full pin-t pin-l">
          <span>Add / Remove Columns</span>
          <span
            onClick={() => this.props.handleColumnFilterBtn()}
            className="cursor-pointer flex flex-center px-3  absolute pin-r pin-t h-full hover:text-hero"
          >
            <i className="fa fa-times" />
          </span>
        </div>
        {this.state.filterData && (
          <div className="content-container overflow-auto text-xs text-body-text">
            {this.state.filterData.map((data, index) => (
              <div
                onClick={() => this.props.handleFilterItem(data, index)}
                key={index}
                className="items cursor-pointer hover:bg-bg px-4 py-2 bg-theme-light flex justify-start items-center  text-body"
              >
                {data.isSelected ? (
                  <div className="checkbox border-black rounded text-white w-4 h-4 mr-2 bg-black flex justify-center items-center">
                    <i className="fa fa-check text-xxs " />
                  </div>
                ) : (
                    <div className="checkbox border-rowBorder rounded w-4 h-4 mr-2 bg-rowBorder" />
                  )}
                {data.filterName}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}
