import React, { Component } from "react";
import './custom.scss';
export default class CustomHeader extends Component {
    constructor(props) {
        super(props);
        props.reactContainer.style.display = "inline-block";
        props.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
        this.state = {
            displayName: props.displayName,
            activeSortType: true
        }
    }

    componentDidMount() {
        this.onSortChanged();
    }
    componentWillUnmount() {
        this.props.column.removeEventListener('sortChanged', this.onSortChanged.bind(this));
    }
    onMenuClicked() {
        this.props.showColumnMenu(this.menuButton);
    }
    componentWillReceiveProps(props) {
        // console.log(props, "recieved");
    }
    updateSorting(name) {
        this.columnsClicked(name);
    }
    onSortChanged() {
        this.setState({
            ascSort: this.props.column.isSortAscending() ? 'active' : 'inactive',
            descSort: this.props.column.isSortDescending() ? 'active' : 'inactive',
            noSort: !this.props.column.isSortAscending() && !this.props.column.isSortDescending() ? 'active' : 'inactive'
        });
    }
    render() {
        return (
            <div className="flex cursor-pointer" onClick={() => this.updateSorting(this.props.displayName)}>
                <div className="customHeaderLabel" dangerouslySetInnerHTML={{ __html: this.props.displayName }} />
            </div>
        );
    }
}