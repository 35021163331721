const setSectorData = payload => ({
  type: "SET_SECTOR_DATA",
  payload
});
const setSectorState = payload => ({
  type: "SET_SECTOR_STATE",
  payload
})
export const mutation = {
  setSectorData,
  setSectorState
};
