import React, { Component } from "react";
import "./gccFilter.scss";
export default class GccFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subFilters: [
        { value: "includes", label: "includes" },
        { value: "does not include", label: "does not include" }
      ],
      selectedSubFilter: props.FilterData.length
        ? props.FilterData[0].selectedSubFilter.value === "includes"
          ? true
          : false
        : true,
      dependentFilter: [
        { value: "Very High", label: "Very High" },
        { value: "High", label: "High" },
        { value: "Medium", label: "Medium" },
        { value: "Low", label: "Low" },
        { value: "Very Low", label: "Very Low" }
      ],
      selectedDependentFilter: props.FilterData.length
        ? props.FilterData[0].selectedDependentFilter
        : []
    };
  }
  handleGccTitleClick = obj => {
    window.open(obj.url);
  };
  //function to handle the subFilter...........................................................
  handleSubFilter = () => {
    this.setState({
      selectedSubFilter: !this.state.selectedSubFilter
    });
  };
  //function to handle the  selected dependentFilter .....................................................
  handleSelectedDependentFilter = val => {
    let selectedFilters = Object.assign([], this.state.selectedDependentFilter);
    for (let i = 0; i < selectedFilters.length; i++) {
      if (val.value == selectedFilters[i].value) {
        return;
      }
    }
    selectedFilters.push(val);
    this.setState({ selectedDependentFilter: selectedFilters });
  };
  //function to handle the removing of the subFilter...........................................
  removeSelectedFilter = data => {
    let selectedFilters = Object.assign([], this.state.selectedDependentFilter);
    selectedFilters = selectedFilters.filter((obj, index) => {
      return obj.value != data.value;
    });
    this.setState({ selectedDependentFilter: selectedFilters });
  };
  //function to handleApplyFilter........................................................
  handleApplyFilter = () => {
    if (!this.state.selectedDependentFilter.length) {
      this.handleRemoveFilter();
    } else {
      let clonedState = {
        subFilters: [
          { value: "includes", label: "includes" },
          { value: "does not include", label: "does not include" }
        ],
        selectedSubFilter:
          this.state.selectedSubFilter === true
            ? { value: "includes", label: "includes" }
            : { value: "does not include", label: "does not include" },
        dependentFilter: [
          { value: "Very High", label: "Very High" },
          { value: "High", label: "High" },
          { value: "Medium", label: "Medium" },
          { value: "Low", label: "Low" },
          { value: "Very Low", label: "Very Low" }
        ],
        selectedDependentFilter: this.state.selectedDependentFilter,
        asynchronous: "false",
        filterName: "GCC Momentum",
        display: "MultiSelect",
        selected: true
      };
      this.props.AppliedFilter("Apply", [clonedState]);
    }
  };
  //function to handle remove filter from Map View.........................................
  handleRemoveFilter = () => {
    this.props.AppliedFilter("remove", []);
  };
  render() {
    return (
      <div>
        <div className="relative header-container flex-between font-bold border-b border-rowBorder px-4 py-3 text-xs absolute bg-white w-full pin-t pin-l">
          <span className="text-xs">GCC Momentum</span>
          <span
            onClick={() => this.props.handleGccFilterBool(0)}
            className="cursor-pointer flex flex-center px-3  absolute pin-r pin-t h-full hover:text-hero"
          >
            <i className="fa fa-times mr-1" />
          </span>
        </div>

        <div className="p-2 cursor-pointer bg-hero-light relative flex-between">
          <span className="text-xs">
            {this.state.selectedSubFilter
              ? this.state.subFilters[0].value
              : this.state.subFilters[1].value}
          </span>
          <div
            onClick={() => {
              this.handleSubFilter();
            }}
            className={
              this.state.selectedSubFilter ? "toggle-btn active" : "toggle-btn"
            }
          />
        </div>
        {this.state.selectedDependentFilter.length > 0 ? (
          <div className="py-2 px-2 border-t border-b flex flex-wrap">
            {this.state.selectedDependentFilter.map(data => (
              <div className="border-divider inline mr-1 py-1 px-2 text-xs mt-1 mb-1 rounded-full border cursor-pointer">
                {data.value}
                <i
                  className="fa fa-times text-xxs ml-1"
                  onClick={() => {
                    this.removeSelectedFilter(data);
                  }}
                />
              </div>
            ))}
          </div>
        ) : null}

        {this.state.dependentFilter.map(data => (
          <div
            className="items cursor-pointer text-xs hover:bg-bg px-4 py-2 bg-theme-light flex justify-start items-center  text-body"
            onClick={() => this.handleSelectedDependentFilter(data)}
          >
            {data.value}
          </div>
        ))}
        <div className="text-center mt-3">
          <button
            className="bg-rowBorder py-3 px-6 text-xs mt-4 absolute w-full pin-l pin-b rounded-0 hover:bg-black hover:text-white"
            onClick={() => {
              this.handleApplyFilter();
            }}
          >
            Apply
          </button>
        </div>
      </div>
    );
  }
}
