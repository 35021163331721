import { Api } from "./config/request";

export const getCountryLevelData = payload => {
  return Api.postRequest("fetchCountryIndustryCount?", payload);
};
export const getStateLevelData = payload => {
  return Api.postRequest("fetchRegionIndustryCount?", payload);
};
export const getCityLevelData = payload => {
  return Api.postRequest("fetchCityIndustryCount?", payload);
};
export const Map = {
  getCountryLevelData,
  getStateLevelData,
  getCityLevelData
};
