import React, { Component } from "react";
import "./sectorView.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as sectorActions from "store/sector/actions";
import { withRouter } from "react-router-dom";
import DataTable from "components/common/sectorTable/sectorTable.js";
import searchMethod from "utils/searchMethods";
import GccFilter from "components/common/gccFilter/gccFilter";
import sectorHeader from "../common/sectorHeader/sectorHeader.jsx";
export class SectorView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sectorData: { rows: [], columns: [] },
      cols: ["United States", "United Kingdom", "Germany", "France", "Japan", "China", "South Korea", "India", "Saudi Arabia", "United Arab Emirates", "Kuwait", "Oman", "Qatar", "Bahrain"],
      showGCCFilter: 0,
      filterItem: Object.assign([], props.gccFilter.Data),
      modifiedFilterItem: props.gccFilter.modifiedData ? props.gccFilter.modifiedData : null
    };
  }
  // life cycle methods.......................................................................
  componentDidMount() {
    console.log(this.state)
    this.props.requestGetSectorData({ "filters": this.state.modifiedFilterItem ? [this.state.modifiedFilterItem] : [] });
  }
  componentWillReceiveProps(props) {
    if (props.sectorData && props.sectorData !== this.props.sectorData) {
      let cols = ["Sector", ...this.state.cols];
      this.setState({
        sectorData: {
          columns: this.handleConstructionofColumns(cols), rows: this.handleConstructionofRows(cols, Object.assign([], props.sectorData.rows))
        }
      }, function () {
        console.log(this.state)
      });
    }
  }
  //create cols for tabularData .........................................................
  handleConstructionofColumns = cols => {
    let colList = [];

    if (Array.isArray(cols)) {
      colList = cols.map((value, index) => {
        let colObj = { headerName: value.replace(/_/g, " "), field: value, sortable: true };
        if (colObj.field == "Sector")
          colObj.cellStyle = { "font-family": "HelveticaNeueMedium", "font-size": "12px", "font-weight": "bold", "color": "#000 !important" }
        else {
          colObj.headerComponentParams = { menuIcon: require("static/images/" + value + ".png") }
        }
        return colObj;
      });
    } else {
      for (let keyName in cols) {
        let colObj = { headerName: cols[keyName], field: keyName };
        colList.push(colObj);
      }
    }
    console.log(colList)
    return colList;
  };
  //function to handle the header Name...............................................
  headerCellRenderer = (a) => {
    console.log(a);
  }
  //function to handle the construction of the columns................................
  handleConstructionofRows = (cols, rows) => {
    var Modifiedrows = []
    var rowsObj = {};
    for (var i = 0; i < rows.length; i++) {
      if (rows[i].sectorName) {
        rowsObj = {
          "Sector": rows[i].sectorName
        }
        for (var j = 0; j < cols.length; j++) {
          for (var k = 0; k < rows[i].countryCount.length; k++) {
            if (cols[j] === rows[i].countryCount[k].countryName) {
              rowsObj[cols[j]] = {
                "Info": rows[i].countryCount[k]
              }
              break;
            }
          }
        }
        Modifiedrows.push(rowsObj);
      }
    }
    return Modifiedrows
  }
  //function to handle the GCC filter bool...............................................
  handleGccFilterBool = () => {
    this.setState((prevState) => ({
      showGCCFilter: !prevState.showGCCFilter
    }))
  }
  AppliedFilter = (operation, item) => {
    let modifiedItem = null;
    if (item.length) {
      let modifiedData = {
        filterName: item[0].filterName,
        subFilters: item[0].selectedSubFilter.value,
        dependentFilter: item[0].selectedDependentFilter.map((data, index) => {
          return data.value
        })
      }
      modifiedItem = modifiedData;
    }
    this.props.setGccFilterData({ Data: item, modifiedData: modifiedItem });
    this.setState({ filterItem: item, modifiedFilterItem: modifiedItem }, () => {
      this.props.requestGetSectorData({ "filters": modifiedItem ? [modifiedItem] : [] });
      this.saveSectorState();
      this.handleGccFilterBool();
    });
  };
  handleGccItem = (data, type, view) => {
    this.props.handleGccAlertItem(data, type, view, this.state.modifiedFilterItem ? [this.state.modifiedFilterItem] : [])
  }
  //function to save the the Sector State............................................................
  saveSectorState = () => {
    let ClonedState = Object.assign({}, { filterItem: this.state.filterItem, modifiedFilterItem: this.state.modifiedFilterItem });
    this.props.saveSectorState(ClonedState);
  }
  //function to handle the Gcc filter and Navigate to the list view ................................
  setFiltersAndNavigate = (data) => {
    let state = [
      {
        filterName: "Country",
        selectedSubFilter: { value: "includes", label: "includes" },
        selected: true,
        selectedDependentFilter: [{ label: data.countryName, value: data.countryName }],
        filterHeader: "Basic Info",
        display: "MultiSelect",
        dependentFilter: "id=4",
        asynchronous: true,
        subFilters: [{ label: "includes", value: "includes" }, { label: "does not include", value: "does not include" }]
      },
      {
        filterName: "Sector",
        selectedSubFilter: { value: "includes", label: "includes" },
        selected: true,
        selectedDependentFilter: [{ label: data.Sector, value: data.Sector }],
        filterHeader: "Categories",
        display: "MultiSelect",
        dependentFilter: "id=1",
        asynchronous: true,
        subFilters: [{ label: "includes", value: "includes" }, { label: "does not include", value: "does not include" }]
      }
    ];
    this.props.setFilterData(state);
    // if (this.state.filterItem.length) {
    //   state.push(this.state.filterItem[0]);
    // }
    this.props.history.push("/dashboard/table");
  }
  // render function................................................................
  render() {
    return (
      <div className="sector-view">
        <div className="m-8 fixed pin-r" style={{ "z-index": "10000", "top": "65px" }}>
          {/* <button
            className="square-btn  cursor-pointer"
            onClick={this.handleGccFilterBool}
          >
            <i className="fa fa-filter" />
          </button> */}
          {this.state.showGCCFilter ? (
            <div
              className="bg-white filter-container overflow-hidden rounded shadow  absolute w-full z-10 pin-r"
              style={{ height: "330px" }}
            >
              <GccFilter
                FilterData={this.state.filterItem}
                handleGccFilterBool={this.handleGccFilterBool}
                AppliedFilter={this.AppliedFilter}
              />
            </div>
          ) : null}
        </div>
        <DataTable rows={this.state.sectorData.rows} columns={this.state.sectorData.columns} globalRequestCycleStatus={this.props.globalRequestCycleStatus} handleGccItem={this.handleGccItem} setFiltersAndNavigate={this.setFiltersAndNavigate} sectorHeader={sectorHeader} />
      </div>
    );
  }
};
export default withRouter(
  connect(
    ({ sector, globalRequest }) => ({ ...sector, ...globalRequest }),
    dispatch => bindActionCreators({ ...sectorActions }, dispatch)
  )(SectorView)
);
