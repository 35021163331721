import React, { Component } from "react";
import "./overview.scss";
export default class overview extends Component {
  constructor(props) {
    super();
    this.state = {
      limit: 300
    };
  }
  handleReadMore = limitValue => {
    this.setState({
      limit: limitValue === 300 ? -1 : 300
    });
  };
  render() {
    return (
      <div className="component-overview">
        {this.props.overview && (
          <div className="font-lg font-fbold mb-2 text-lg">Overview</div>
        )}
        {this.props.overview && this.props.overview != "null" ? (
          <div className="mb-2 text-sub-text leading-normal font-fmedium text-xs tracking-wide">
            {this.props.overview.slice(0, this.state.limit)}
            {this.state.limit === -1
              ? this.props.overview[this.props.overview.length - 1]
              : ""}
            {this.props.overview.length > this.state.limit
              ? this.state.limit !== -1
                ? "..."
                : ""
              : ""}
            {this.props.overview.length > this.state.limit ? (
              <span
                className="text-hero cursor-pointer"
                onClick={() => this.handleReadMore(this.state.limit)}
              >
                {this.state.limit === 300 ? "Read More" : "Show Less"}
              </span>
            ) : (
                ""
              )}
          </div>
        ) : "-"}
        <div className="font-lg mb-2 text-lg font-fbold">Social</div>
        {this.props.social ? (
          <div className="flex">
            {this.props.social["facebook"] && this.props.social["facebook"] != "null" ? (
              <a
                href={this.props.social.facebook}
                className="social-icon facebook"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook-f" />
              </a>
            ) : null}

            {this.props.social["google-plus"] && this.props.social["google-plus"] != "null" ? (
              <a
                href={this.props.social.google_plus}
                className="social-icon google-plus"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-google-plus-g" />
              </a>
            ) : null}

            {this.props.social["twitter"] && this.props.social["twitter"] != "null" ? (
              <a
                href={this.props.social.twitter}
                className="social-icon twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter" />
              </a>
            ) : null}

            {this.props.social["linkedin"] && this.props.social["linkedin"] != "null" ? (
              <a
                href={this.props.social.linkedin}
                className="social-icon linkedin"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin-in" />
              </a>
            ) : null}

            {this.props.social["website"] && this.props.social["website"] != "null" ? (
              <a
                href={this.props.social.website}
                className="social-icon website"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fas fa-globe" />
              </a>
            ) : null}

            {this.props.social["crunchBase"] && this.props.social["crunchBase"] != "null" ? (
              <a
                href={this.props.social.crunchBase}
                className="social-icon website text-xxs"
                target="_blank"
                rel="noopener noreferrer"
              >
                CB
              </a>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}
