import React, { Component } from "react";
import "./tab.scss";
export default class tab extends Component {
  render() {
    const props = this.props;
    return (
      <div className="component-tab">
        <div className="theme-tab inline-block font-fmedium text-sm tracking-wide">
          {props.tabList.map((tab, index) => (
            <li
              key={index}
              onClick={() => props.handleButtonClick(index)}
              className={props.activeTab == tab.url ? " tab active" : "tab"}
            >
              {tab.name}
            </li>
          ))}
        </div>
      </div>
    );
  }
}
