class SearchMethods {
  //   constructor() {}
  filterArrayWithKey = (text, Array) => {
    let filteredElements = [];
    for (let i = 0; i < Array.length; i++) {
      let ArrayObject = Array[i];
      for (let ObjectKey in ArrayObject) {
        if (ArrayObject[ObjectKey].length && ArrayObject[ObjectKey].toString().toLowerCase().indexOf(text.toLowerCase()) > -1) {
          filteredElements.push(ArrayObject);
          break;
        }
      }
    }
    return filteredElements;
  };
  filterArrayWithoutKey = (text, Array) => {
    let filteredElements = [];
    for (let i = 0; i < Array.length; i++) {
      for (let j = 0; j < Array[i].length; j++) {
        if (Array[i][j].indexOf(text) > -1) {
          filteredElements.push(Array[i]);
          break;
        }
      }
    }
    return filteredElements;
  };
  removeItemsFromArray(items, array) {
    if (array != undefined && array.length !== 0) {
      for (let i = 0; i < items.length; i++) {
        for (let j = 0; j < array.length; j++) {
          if (typeof array[j] == "string") {
            if (array[j].indexOf(items[i]) > -1) {
              array.splice(j, 1);
              j--;
            }
          } else {
            for (let keyName in array[j]) {
              if (typeof array[j][keyName] == "string" && array[j][keyName].indexOf(items[i]) > -1) {
                array.splice(j, 1);
                j--;
                break;
              }
            }
          }
        }
      }
      return array;
    }
    return [];
  }
}
export default new SearchMethods();
