import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import "./company.scss";
import "../../../node_modules/react-paginate/demo/styles/style.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as companyActions from "store/company/actions";
import * as globalRequestActions from "store/globalRequest/actions";
import colImage from "static/images/column.png";
import Tab from "components/common/tab";
import Filter from "components/common/columnsFilters/filter";
import FilterList from "components/common/filterList/filterList";
import DownloadExcel from "utils/jsonToExcel";
import Map from "components/common/map/map.js";
import DataTable from "components/common/tabular/tabular";
import SelectedFilter from "components/common/selectedFilter/selectedFilter.js";
import GccNews from "components/common/gccNews/gccNews.js";
import GccFilter from "components/common/gccFilter/gccFilter";
// import { globalRequest } from "../../store/globalRequest/reducers";
import CustomHeader from "components/common/customListHeader/customeHeaders";
import SectorView from "components/sectorView/sectorView.js";
import { sector } from "../../store/sector/reducers";
import CompanyAlerts from "components/common/companyAlerts/companyNews.js";
class company extends React.Component {
  constructor(props) {
    super(props);
    let gccFilter = Object.assign([], props.gccFilter.Data);
    this.state = {
      isColumnFilter: false,
      gccAllAlertData: {},
      options: [50, 55, 60, 65, 70, 75, 80],
      showMenu: false,
      startRow: 0,
      endRow: 50,
      columnsList: [],
      rowsList: [],
      list: [],
      showGCCFilter: 0,
      showGccAlert: 0,
      pageCount: 0,
      AppliedFilters: [],
      currentSelectedColumn: "Name",
      currentSortOrder: true,
      recievedFilterList: [],
      mapFilterItem: [],
      multiSelectData: [],
      showFilters: 0,
      gccData: {},
      currentSelectedFilters: [...props.storedfilterData, ...gccFilter],
      customeHeaders: { "agColumnHeader": CustomHeader },
      tabData: [
        { name: "Map View", url: "/dashboard/map" },
        { name: "Sector View", url: "/dashboard/sector" },
        { name: "Table View", url: "/dashboard/table" }
      ],
      table: {
        rows: [],
        columns: []
      }
    };
    CustomHeader.prototype.columnsClicked = this.columnsClicked.bind(this);
  }
  //life cycle methods...................................................................
  componentWillMount() {
    document.addEventListener("mousedown", this.handleInsideClick, false);
  }
  componentWillUnmount() {
    this.props.unsetAllData();
    document.removeEventListener("mousedown", this.handleInsideClick, false);
  }
  columnsClicked(e) {
    let currentSelectedColumn = ""
    if (e.indexOf("<") > -1) {
      let val = new String(e);
      currentSelectedColumn = val.substring(0, e.indexOf("<"));
    } else {
      currentSelectedColumn = e
    }
    let currentSortOrder = !this.state.currentSortOrder;
    if (this.state.currentSelectedColumn != currentSelectedColumn) {
      currentSortOrder = true;
    }
    this.setState({ currentSelectedColumn, currentSortOrder, list: [], rowsList: [] }, () => {
      this.handleAppliedFilter();
    });
  }
  handleInsideClick = e => {
    if (
      this.node &&
      this.otherNode &&
      !this.node.contains(e.target) &&
      !this.otherNode.contains(e.target)
    ) {
      this.handleOutsideClick();
    }
  };
  handleOutsideClick = () => {
    this.setState({
      isColumnFilter: false,
      showMenu: false
    });
  };
  componentDidMount() {
    this.handleGccAlertBool(0);
    if (this.props.location.pathname === "/dashboard/table") {
      this.props.setExplicitGlobalStatus();
      this.handleFormatingOfFilters().then(filters => {
        Promise.all([
          this.props.requestGetFilteredCompanyList({
            lastCount: this.state.startRow,
            recordCount: this.state.endRow,
            filters: filters,
            "fieldName": '`' + this.state.currentSelectedColumn + '`',
            "sortOrder": this.state.currentSortOrder ? "ASC" : "DESC",
          }),
          this.props.fetchFilterList()
        ]);
      });
    }
  }
  componentWillReceiveProps(props) {
    // console.log(props);
    if (props.gccAlertData) {
      this.setState({ gccData: props.gccAlertData });
    }
    if (props.gccAllAlertData) {
      // console.log(props.gccAllAlertData)
      this.setState({ gccAllAlertData: props.gccAllAlertData });
    }
    if (props.multiSelectData && props.multiSelectData.length) {
      this.setState({ multiSelectData: props.multiSelectData });
    }
    if (props.filterList && props.filterList.length) {
      this.setState({ recievedFilterList: props.filterList });
    }
    if (
      props.companyList !== undefined &&
      props.companyList !== "" &&
      props.companyList !== null
    ) {
      this.setState(
        {
          columnsList:
            props.appliedColumns && props.appliedColumns.length
              ? props.appliedColumns
              : props.companyList.columns,
          rowsList: props.companyList.rows,
          pageCount: props.companyList.count / this.state.endRow
        },
        () => {
          if (this.state.rowsList && this.state.columnsList)
            this.handleDataMapping(this.state.rowsList, this.state.columnsList);
        }
      );
    } else {
      this.setState({
        columnsList: [],
        rowsList: []
      });
    }
  }
  //function to handle the mapping of the data .........................................
  handleDataMapping = async (rows, columns) => {
    let tableHeading = [];
    await columns.filter(async data => {
      if (data.isSelected === true) {
        tableHeading.push(data.filterName.trim());
      }
    });
    this.setState({
      list: tableHeading
    });
  };

  //function to handle the route change based on the index.................................
  handleRouteChange = index => {
    this.props.history.push(this.state.tabData[index].url);
  };
  //function to handle the Column filter...........................................
  handleColumnFilterBtn = () => {
    this.setState({
      isColumnFilter: !this.state.isColumnFilter,
      showMenu: false
    });
  };
  //function to handle row when it is clicked and navigate on the basis of company type.........
  handleRowClick = data => {
    let state = {
      Company: [data.data.Name],
      CompanyPermalink: [data.data.Permalink]
    };
    if (
      data.data["Company Type"] === "Private" ||
      data.data["Company Type"] === "Subsidiary"
    ) {
      this.props.history.push(`/company/private`, state);
    } else if (data.data["Company Type"] === "Acquired") {
      this.props.history.push(`/company/acquired`, state);
    } else {
      this.props.history.push(`/company/public`, state);
    }
  };
  //create cols for tabularData .........................................................
  handleConstructionofColumns = cols => {
    let colList = [];
    if (Array.isArray(cols)) {
      colList = cols.map((value, index) => {
        let colObj = { headerName: value.replace(/_/g, " "), field: value };
        if (value === "Name" || value == "Target") {
          colObj.onCellClicked = this.handleRowClick;
          colObj.cellClass = "text-hero";
        }
        if (value == this.state.currentSelectedColumn && this.state.currentSortOrder) {
          colObj.headerName = value + '<div class="ml-2 inline-block"><span class="icon-height icon-width ag-icon ag-icon-asc"></span></div>';
        } else if (value == this.state.currentSelectedColumn && !this.state.currentSortOrder) {
          colObj.headerName = value + '<div class="ml-2 inline-block"><span class="icon-height icon-width ag-icon ag-icon-desc"></span></div>';
        }
        return colObj;
      });
    } else {
      for (let keyName in cols) {
        let colObj = { headerName: cols[keyName], field: keyName };
        if (keyName === "Name") {
          // colObj.onCellClicked = this.handleRowClick;
          colObj.cellClass = "text-hero";
        }
        if (keyName == this.state.currentSelectedColumn && this.state.currentSortOrder) {
          colObj.headerName = keyName + '<div class="ml-2 inline-block"><span class="icon-height icon-width ag-icon ag-icon-asc"></span></div>';
        } else if (keyName == this.state.currentSelectedColumn && !this.state.currentSortOrder) {
          colObj.headerName = keyName + '<div class="ml-2 inline-block"><span class="icon-height icon-width ag-icon ag-icon-desc"></span></div>';
        }
        colList.push(colObj);
      }
    }
    return colList;
  };
  returnCurentSortValue = () => {
    let currentHeaderObj = { activeColumn: this.state.currentSelectedColumn, activeSort: this.state.currentSortOrder }
    return currentHeaderObj;
  }
  //function to handle filterBool.............................................
  handleFilterBool = val => {
    this.setState({ showFilters: val });
  };
  //function to handle the filter item when any filter item is selected and this function is passed as prop...........
  handleFilterItem = (item, index) => {
    let tempColumns = Object.assign([], this.state.columnsList);
    tempColumns[index].isSelected === true
      ? (tempColumns[index].isSelected = false)
      : (tempColumns[index].isSelected = true);
    this.setState({ columnsList: tempColumns }, () => {
      this.handleDataMapping(this.state.rowsList, this.state.columnsList);
      this.props.storeUserAppliedColumns(this.state.columnsList);
    });
  };
  handleMenuToggle = () => {
    this.setState({ showMenu: !this.state.showMenu, isColumnFilter: false });
  };
  //function to convert current data into excel...........................................
  handlePrint = value => {
    if (!this.state.rowsList.length) {
      alert("Nothing to download");
    } else {
      let excelRows = [];
      for (let i = 0; i < this.state.rowsList.length; i++) {
        let excelObject = {};
        for (let j = 0; j < this.state.list.length; j++) {
          Object.defineProperty(excelObject, this.state.list[j], {
            value: this.state.rowsList[i][this.state.list[j]],
            writable: true,
            configurable: true,
            enumerable: true
          });
        }
        excelRows.push(excelObject);
      }
      DownloadExcel(excelRows, "CompanyList", true);
    }
  };
  //function called when pagination is triggered and fetches the reponse based on the selected index......
  handlePageClick = val => {
    this.handleFormatingOfFilters().then(filters =>
      this.props.requestGetFilteredCompanyList({
        lastCount: val.selected === 0 ? 0 : 50 * val.selected,
        recordCount: this.state.endRow,
        filters: filters,
        "fieldName": '`' + this.state.currentSelectedColumn + '`',
        "sortOrder": this.state.currentSortOrder ? "ASC" : "DESC",
      })
    );
  };
  //function to handle the base range ...............................................
  handleBaseRange = value => {
    this.setState({ endRow: value, rowsList: null }, () => {
      this.handleFormatingOfFilters().then(filters => {
        this.props.requestGetFilteredCompanyList({
          lastCount: this.state.startRow,
          recordCount: this.state.endRow,
          filters: filters,
          "fieldName": '`' + this.state.currentSelectedColumn + '`',
          "sortOrder": this.state.currentSortOrder ? "ASC" : "DESC",
        });
      });
    });
  };
  //funtion to handle when filter from filterList is Clicked......................................
  handleFilterItemClick = (parentItem, ChildItem) => {
    let recievedObj = Object.assign({}, { ...parentItem, ...ChildItem });
    this.handleFilterBool(0);
    let currentSelectedFilters = Object.assign(
      [],
      this.state.currentSelectedFilters
    );
    delete recievedObj["filters"];
    let formatedSubFilters = recievedObj.subFilters.map(item => {
      return {
        value: item,
        label: item
      };
    });
    recievedObj.selectedSubFilter = formatedSubFilters[0];
    recievedObj.subFilters = formatedSubFilters;
    currentSelectedFilters.push(recievedObj);
    this.setState({ currentSelectedFilters });
  };
  //handle async menu .................................................................
  handleAsyncMenu = () => {
    this.setState({ multiSelectData: [] });
  }
  //function to handle the subFilter.....................................................
  handleSubFilter = (e, index) => {
    let currentSelectedFilters = Object.assign(
      [],
      this.state.currentSelectedFilters
    );
    let objToBeChanged = currentSelectedFilters[index];
    objToBeChanged.selectedSubFilter = e;
    this.setState({ currentSelectedFilters });
  };
  //function to handle Dependent Filter................................................
  handleDependentFilter = (e, index, type) => {
    let currentSelectedFilters = Object.assign(
      [],
      this.state.currentSelectedFilters
    );
    let objToBeChanged = currentSelectedFilters[index];
    if (type === "between") objToBeChanged.selectedDependentFilter2 = e;
    else objToBeChanged.selectedDependentFilter = e;
    this.setState({ currentSelectedFilters });
  };
  //function to format the filter..............................................................
  handleFormatingOfFilters = async () => {
    let currentFilterItem = [];
    let currentSelectedFilters = await Object.assign(
      [],
      this.state.currentSelectedFilters
    ).map((filterItem, index) => {
      let modifiedFilterItem = {
        filterName: filterItem.filterName,
        subFilters: filterItem.selectedSubFilter.value
      };
      if (filterItem.display === "DropDown") {
        modifiedFilterItem.dependentFilter = [
          filterItem.selectedDependentFilter.value
        ];
      } else if (filterItem.display === "DatePicker") {
        if (filterItem.selectedSubFilter.value !== "between") {
          modifiedFilterItem.dependentFilter = [
            filterItem.selectedDependentFilter.getFullYear().toString()
          ];
        } else {
          modifiedFilterItem.dependentFilter1 = [
            filterItem.selectedDependentFilter.getFullYear().toString()
          ];
          modifiedFilterItem.dependentFilter2 = [
            filterItem.selectedDependentFilter2.getFullYear().toString()
          ];
        }

      } else if (filterItem.display === "TextBox") {
        if (filterItem.selectedSubFilter.value !== "between") {
          modifiedFilterItem.dependentFilter = [
            filterItem.selectedDependentFilter
          ];
        } else {
          modifiedFilterItem.dependentFilter1 = [
            filterItem.selectedDependentFilter
          ];
          modifiedFilterItem.dependentFilter2 = [
            filterItem.selectedDependentFilter2
          ];
        }
      } else if (filterItem.display === "MultiSelect") {
        modifiedFilterItem.dependentFilter = filterItem.selectedDependentFilter.map(
          (item, index) => {
            return item.value;
          }
        );
      }
      if (filterItem.filterName == "GCC Momentum") {
        this.props.setGccFilterData({ Data: [filterItem], modifiedData: modifiedFilterItem })
      }
      return modifiedFilterItem;
    });
    // console.log(this.props.location.state);
    return currentSelectedFilters;
  };
  //function to handle the apply filter........................................................
  handleAppliedFilter = () => {
    this.setState({ rowsList: [] }, () => {
      this.handleFormatingOfFilters().then(filters => {
        this.saveNonGccFilters(this.state.currentSelectedFilters);
        this.props.requestGetFilteredCompanyList({
          lastCount: this.state.startRow,
          recordCount: this.state.endRow,
          filters: filters,
          "fieldName": '`' + this.state.currentSelectedColumn + '`',
          "sortOrder": this.state.currentSortOrder ? "ASC" : "DESC",
        });
      });
    });
  };
  //function to save Non Gcc filters................................................
  saveNonGccFilters = (filters) => {
    let clonedFilters = Object.assign([], filters);
    let selectedClonedFilters = []
    let gccFound = 0;
    selectedClonedFilters = clonedFilters.filter((item, index) => {
      if (item.filterName !== "GCC Momentum") {
        return true;
      }
      else {
        gccFound = 1;
        return false;
      }
    });
    this.props.setFilterData(selectedClonedFilters);
    if (!gccFound) {
      this.props.setGccFilterData({ Data: [], modifiedData: null });
    }
  }
  //handle remove selected filter.................................................................
  handleRemoveSelectedFilter = index => {
    let currentSelectedFilters = Object.assign(
      [],
      this.state.currentSelectedFilters
    );
    currentSelectedFilters.splice(index, 1);
    this.setState({ currentSelectedFilters }, () => {
      if (!currentSelectedFilters.length) {
        this.props.setFilterData([]);
        this.props.setGccFilterData({ Data: [], modifiedData: null })
        this.setState({ rowsList: [] }, () => {
          this.props.storeUserAppliedFilters(null);
          this.handleFormatingOfFilters().then(filters => {
            this.props.requestGetFilteredCompanyList({
              lastCount: this.state.startRow,
              recordCount: this.state.endRow,
              filters: filters,
              "fieldName": '`' + this.state.currentSelectedColumn + '`',
              "sortOrder": this.state.currentSortOrder ? "ASC" : "DESC",
            });
          });
        });
      }
    });
  };
  //function to fetch details for gcc alert item..................................
  handleGccAlertItem = (Detail, locObj, view, filter) => {
    let gccString = "";
    if (view === "Map") {
      if (locObj.cityName !== undefined) {
        gccString =
          "country=" +
          locObj.countryName +
          "&region=" +
          locObj.regionName +
          "&city=" +
          locObj.cityName +
          "&sector=" +
          Detail.industryName;
      } else {
        gccString =
          "country=" +
          locObj.countryName +
          "&region=" +
          locObj.regionName +
          "&city=" +
          "&sector=" +
          Detail.industryName;
      }
      this.props.requestGetGccAlertNews(gccString, { filters: filter });
    } else if (view == "CompanyView") {
      //console.log(Detail.data.Permalink)
      this.props.requestAllAlertAndGccNewsData({
        permalink: Detail.data.Permalink
      }, { permalink: [Detail.data.Permalink], type: ["Signal"] });
    } else {
      gccString =
        "country=" +
        Detail.countryName +
        "&region=&city=&sector=" + Detail.Sector;
      this.props.requestGetGccAlertNews(gccString, { filters: filter });
    }
    this.handleGccAlertBool(1);
  };
  // handleGccAllAlertItem = (permalink, type) => {
  //   //   this.props.requestGetGccAllAlertNews({
  //   //     permalink: [permalink],
  //   //     type: [type]
  //   //   });
  //   // }
  //function to handle the gcc alert bool................................................
  handleGccAlertBool = val => {
    this.setState({ showGccAlert: val }, () => {
      // if (this.state.showGccAlert === 1 && this.props.location.pathname === "/dashboard/table") {
      //   document.body.classList.add('gcc-active');
      // } else if (this.state.showGccAlert === 0 && this.props.location.pathname === "/dashboard/table") {
      //   document.body.classList.remove('gcc-active');
      // }
    });
    this.props.requestPreventOverflow(val);
  };
  //function to handle multiselectInput...........................................................
  handleMulltiSelectInput = (e, index, item) => {
    if (e !== "") {
      if (this.currentTimeOut) {
        clearTimeout(this.currentTimeOut);
        this.currentTimeOut = undefined;
      }
      this.currentTimeOut = setTimeout(() => {
        this.props.fetchMultiselectDependentData(item.dependentFilter, {
          filter: { searchKeyword: e, type: item.filterName }
        });
      }, 700);
    }
  };
  //function to handle alert click.................................................................
  handleAlertClick = obj => {
    window.open(obj.url);
  };
  sortChanged = (a, b) => {
    // console.log(a, b)
  }
  handleGccFilterBool = val => {
    this.setState({ showGCCFilter: val });
  };
  //render function.........................................................................
  render() {
    const state = this.state;
    const props = this.props;
    return (
      <div className="component-company view">
        {props.location.pathname === "/dashboard/table" && (
          <div
            className={
              state.showGccAlert ? "alert-backdrop active" : "alert-backdrop"
            }
          />
        )}

        <div className="flex-between">
          <Tab
            handleButtonClick={value => this.handleRouteChange(value)}
            tabList={state.tabData}
            activeTab={props.location.pathname}
          />

          {/* ..................Map View Filter......... */}
          {/* {props.location.pathname === "/dashboard/map" && (
            <div>
              <button
                className="square-btn"
                onClick={() => {
                  this.handleGccFilterBool(!state.showGCCFilter);
                }}
              >
                <i className="fa fa-filter" />
              </button>
              {state.showGCCFilter ? (
                <div
                  className="bg-white filter-container overflow-hidden rounded shadow  absolute w-full z-10 pin-r"
                  style={{ height: "330px" }}
                >
                  <GccFilter
                    mapFilterData={this.state.mapFilterItem}
                    handleGccFilterBool={this.handleGccFilterBool}
                    mapAppliedFilter={this.mapAppliedFilter}
                  />
                </div>
              ) : null}
            </div>
          )} */}


          {/* ..................Table View Filter......... */}
          {props.location.pathname === "/dashboard/table" && (
            <div className="btn-container flex justify-start">
              <div ref={node => (this.node = node)} className="relative">
                <button
                  className={
                    this.state.isColumnFilter
                      ? "square-btn mr-2 active"
                      : "square-btn mr-2"
                  }
                  onClick={() => this.handleColumnFilterBtn()}
                >
                  <img
                    className="img-responsive column-image"
                    style={{ height: 15 }}
                    src={colImage}
                    alt="."
                  />
                </button>
                {state.isColumnFilter && (
                  <div className="filter-container overflow-hidden rounded shadow w-24 absolute w-full z-10 pin-r">
                    <Filter
                      filterData={this.state.columnsList}
                      handleFilterItem={this.handleFilterItem}
                      handleColumnFilterBtn={() => this.handleColumnFilterBtn()}
                    />
                  </div>
                )}
              </div>
              <div>
                <button className="square-btn mr-2" onClick={this.handlePrint}>
                  <i className="fas fa-file-download" />
                </button>
              </div>
              {/* // filter list section............................................... */}
              <div>
                <button
                  className="square-btn"
                  onClick={() => {
                    this.handleFilterBool(1);
                  }}
                >
                  <i className="fa fa-filter" />
                </button>
                {state.showFilters ? (
                  <div className="filter-container overflow-hidden rounded shadow  absolute w-full z-10 pin-r">
                    <FilterList
                      recievedFilters={state.recievedFilterList}
                      selectedFilters={state.AppliedFilters}
                      handleFilterItemClick={this.handleFilterItemClick}
                      handleFilterBool={this.handleFilterBool}
                    />
                  </div>
                ) : null}
              </div>
              {/* //end of filter list section.................................................. */}
            </div>
          )}
        </div>
        {/* ..........................Route Based component View.............. */}
        <div>
          {/* ....................Map View................. */}

          {props.location.pathname === "/dashboard/map" ? (
            <Map
              handleGccAlertItem={this.handleGccAlertItem}
              handleGccAlertBool={this.handleGccAlertBool}
              gccFilter={this.props.gccFilter}
            />
          ) : null}

          {this.state.showGccAlert &&
            props.location.pathname === "/dashboard/map" ? (
              <GccNews
                gccData={this.state.gccData}
                handleGccAlertBool={this.handleGccAlertBool}
                containerClass={"gcc-container-map"}
              />
            ) : null}

          {/* ...................Sector View............. */}
          {props.location.pathname === "/dashboard/sector" ? (
            <div className="mt-6 list-container bg-white">
              <SectorView handleGccAlertItem={this.handleGccAlertItem} gccFilter={this.props.gccFilter} />
              {this.state.showGccAlert &&
                props.location.pathname === "/dashboard/sector" ? (
                  <GccNews
                    gccData={this.state.gccData}
                    handleGccAlertBool={this.handleGccAlertBool}
                    containerClass={"gcc-container-map"}
                  />
                ) : null}
            </div>
          ) : null}

          {/* .....................Table View ................... */}

          {props.location.pathname === "/dashboard/table" ? (
            <div>
              {state.currentSelectedFilters.length > 0 && (
                <div className="filter-section p-4 mt-2 min-w-24 rounded border border-rowBorder bg-white">
                  <SelectedFilter
                    appliedFilters={this.state.currentSelectedFilters}
                    handleSubFilter={this.handleSubFilter}
                    handleDependentFilter={this.handleDependentFilter}
                    handleRemoveSelectedFilter={this.handleRemoveSelectedFilter}
                    handleMulltiSelectInput={this.handleMulltiSelectInput}
                    multiSelectData={this.state.multiSelectData}
                    handleAsyncMenu={this.handleAsyncMenu}
                  />
                  {state.currentSelectedFilters.length ? (
                    <button
                      className="theme-btn px-6 text-xs mt-4"
                      onClick={() => {
                        this.handleAppliedFilter();
                      }}
                    >
                      Apply Filter
                    </button>
                  ) : null}
                </div>
              )}
              {/* End of filter */}
              <div className="mt-6 list-container bg-white">
                <DataTable
                  columns={this.handleConstructionofColumns(this.state.list)}
                  rows={this.state.rowsList}
                  height={"calc(100vh - 222px)"}
                  handleGccAlertItem={this.handleGccAlertItem}
                  frameworkComponents={this.state.customeHeaders}
                  handleGccAllAlertItem={this.handleGccAllAlertItem}
                />
              </div>
              <div className="flex justify-between items-center mt-2">
                <div
                  ref={otherNode => (this.otherNode = otherNode)}
                  className="flex justify-start items-center py-2"
                >
                  <span className="mr-2 text-xs text-light font-fmedium">
                    Showing
                  </span>
                  {props.companyList.count > this.state.endRow ? (
                    <div className="drop-up">
                      <div
                        className="menu-toggle text-center"
                        onClick={() => this.handleMenuToggle()}
                      >
                        {this.state.endRow}
                      </div>
                      {state.showMenu && (
                        <div className="menu-list">
                          {state.options.map((option, index) => (
                            <div
                              key={index}
                              onClick={() => this.handleBaseRange(option)}
                            >
                              {option}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ) : (
                      " "
                    )}
                  <span className="ml-2 text-xs text-light">
                    {props.companyList.count > this.state.endRow ? "of " : ""}
                    {props.companyList.count}
                    {" entries"}
                  </span>
                </div>

                {this.state.showGccAlert &&
                  props.location.pathname === "/dashboard/table" ? (
                    <div className="h-full absolute h-full w-full pin-l pin-t flex-center">
                      <CompanyAlerts gccData={this.state.gccData}
                        gccAllAlertData={this.state.gccAllAlertData}
                        handleGccAlertBool={this.handleGccAlertBool} containerClass={"gcc-container-list"}
                        globalRequest={this.props.globalRequestCycleStatus}
                      />
                    </div>
                  ) : null}

                {this.state.rowsList && this.state.rowsList.length ? (
                  <div>
                    <ReactPaginate
                      previousLabel={<i className="fa fa-chevron-left" />}
                      nextLabel={<i className="fa fa-chevron-right" />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
export default connect(
  ({ company, filter, globalRequest }) => ({
    ...company,
    ...filter,
    ...globalRequest
  }),
  dispatch =>
    bindActionCreators({ ...companyActions, ...globalRequestActions }, dispatch)
)(company);
