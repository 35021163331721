import { initialState } from "./states";

export const filter = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FILTER_LIST": {
      return Object.assign({}, state, {
        filterList: action.payload
      });
    }
    case "SET_MULTISELECT_DATA": {
      return Object.assign({}, state, {
        multiSelectData: action.payload
      });
    }
    case "SET_FILTER_DATA": {
      return Object.assign({}, state, {
        storedfilterData: action.payload
      });
    }
    case "SET_GCC_FILTER_DATA": {
      return Object.assign({}, state, {
        gccFilter: { Data: action.payload.Data, modifiedData: action.payload.modifiedData }
      });
    }
    default:
      return state;
  }
};
