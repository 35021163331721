const setGlobalRequest = payload => ({
  type: "SET_GLOBAL_REQUEST",
  payload
});
const setBackDropActive = payload => ({
  type: "SET_BACKDROP_ACTIVE",
  payload
});
const setModalComponent = payload => ({
  type: "SET_MODAL_COMPONENT",
  payload
});
export const setOverflow = payload => ({
  type: "SET_OVERFLOW",
  payload
});

export const mutation = {
  setGlobalRequest,
  setBackDropActive,
  setModalComponent,
  setOverflow
};
