import { initialState } from "./states";
export const map = (state = initialState, action) => {
  switch (action.type) {
    case "SET_MAP_DATA": {
      return Object.assign({}, state, {
        mapData: action.payload
      });
    }
    case "SET_MAP_COMPONENT_DATA": {
      return Object.assign({}, state, {
        mapComponentData: action.payload
      });
    }
    default:
      return state;
  }
};
