import * as React from "react";
import { AgGridReact } from "ag-grid-react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import emptyImage from "static/images/empty-result.png";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "./tabular.scss";
import moment from "moment"
import { ValueFormatterService } from "ag-grid-community";
class DataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cols: [],
      rows: [],
      overlayLoadingTemplate:
        '<span class="ag-overlay-loading-center">Please wait while result is loaded</span>',
      overlayNoRowsTemplate: "No rows to display",
      loading:
        '<span class="ag-overlay-loading-center">Please wait while result is loaded</span>'
    };
  }
  // //handle Loading OF templte ........................................................................
  // handleLoadingTemplate = (props, state) => {
  //   if (props.rows !== undefined && props.rows !== null && props.rows.length)
  //     return state.overlayLoadingTemplate;
  //   else if (props.globalRequestCycleStatus.isProcessing)
  //     return state.overlayLoadingTemplate;
  //   else
  //     return state.overlayNoRowsTemplate;
  // }
  // life cycle method...............................................................
  componentWillReceiveProps(props) {
    this.setState((prevState) => {
      return {
        rows: props.rows !== undefined && props.rows !== null ? props.rows : [],
        // loading: this.handleLoadingTemplate(props, prevState),
        cols: props.columns && props.rows && props.rows.length !== 0 ? props.columns : []
      }
    }, () => {
    });
    let data = props.columns.map((value, index) => {
      if (value.headerName === "Name" || value.headerName === "Company Description" || value.headerName === "Description" || value.headerName === "Snippet" || value.headerName === "Title")
        value.width = value.headerName === "Name" ? 300 : 500;
      else
        value.width = props.columns.length < 6 ? 300 : 200;
      if (value.headerName == "Date") {
        value.comparator = function (filterLocalDateAtMidnight, cellValue) {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          var dateParts = moment(cellValue).format('MM/DD/YYYY').split("/");
          var day = Number(dateParts[2]);
          var month = Number(dateParts[1]) - 1;
          var year = Number(dateParts[0]);
          var cellDate = new Date(day, month, year);
          // Now that both parameters are Date objects, we can compare
          if (moment(cellValue).isBefore(filterLocalDateAtMidnight)) {
            return -1;
          } else if (moment(cellValue).isAfter(filterLocalDateAtMidnight)) {
            return 1;
          } else {
            return 0;
          }
        }
      }
      // value.editable = false;
      // value.cellEditor = "agLargeTextCellEditor";
      // value.cellEditorParams = {
      //   maxLength: "300", // override the editor defaults
      //   cols: "50",
      //   rows: "6"
      // };
      value.suppressSizeToFit = true;

      value.cellRenderer = function (params) {
        if (value.field == "Name") {
          if (params.data["GCC-Alert"] === "Yes") {
            var eButton = document.createElement('button');
            eButton.className = "fa fa-bell text-notify float-right p-2"
            eButton.addEventListener('click', (e) => {
              e.stopPropagation();
              this.props.handleGccAlertItem(params, null, "CompanyView", []);
            });
            var eDiv = document.createElement("div");
            eDiv.appendChild(document.createTextNode(params.value));
            eDiv.appendChild(eButton);
            return eDiv;

          } else {
            return params.value;
          }
        } else if (value.field == "SocialNetworkLinks") {
          // value.cellRenderer = function (params) {
          let socialItems = params.data.SocialNetworkLinks[0].length ? params.data.SocialNetworkLinks[0] : [];
          let eSocial = document.createElement("div");
          eSocial.setAttribute("class", "flex p-1");
          for (let i = 0; i < socialItems.length; i++) {
            let eSocialAnchor = document.createElement("a");
            if (socialItems[i] && socialItems[i].indexOf("linkedin") > -1) {
              eSocialAnchor.setAttribute("href", socialItems[i]);
              eSocialAnchor.setAttribute("class", "social-icon linkedin");
              eSocialAnchor.setAttribute("target", "_blank");
              eSocialAnchor.setAttribute("rel", "noopener noreferrer");
              let iconDiv = document.createElement("i");
              iconDiv.setAttribute("class", "fab fa-linkedin-in");
              eSocialAnchor.appendChild(iconDiv);
              eSocial.appendChild(eSocialAnchor);
            }
            else if (socialItems[i] && socialItems[i].indexOf("crunchbase") > -1) {
              eSocialAnchor.setAttribute("href", socialItems[i]);
              eSocialAnchor.setAttribute("class", "social-icon website text-xxs");
              eSocialAnchor.setAttribute("target", "_blank");
              eSocialAnchor.setAttribute("rel", "noopener noreferrer");
              let iconDiv = document.createTextNode("CB");
              eSocialAnchor.appendChild(iconDiv);
              eSocial.appendChild(eSocialAnchor);
            }
            else if (socialItems[i] && socialItems[i].indexOf("twitter") > -1) {
              eSocialAnchor.setAttribute("href", socialItems[i]);
              eSocialAnchor.setAttribute("class", "social-icon twitter");
              eSocialAnchor.setAttribute("target", "_blank");
              eSocialAnchor.setAttribute("rel", "noopener noreferrer");
              let iconDiv = document.createElement("i");
              iconDiv.setAttribute("class", "fab fa-twitter");
              eSocialAnchor.appendChild(iconDiv);
              eSocial.appendChild(eSocialAnchor);
            }
            else if (socialItems[i] && socialItems[i].indexOf("facebook") > -1) {
              eSocialAnchor.setAttribute("href", socialItems[i]);
              eSocialAnchor.setAttribute("class", "social-icon facebook");
              eSocialAnchor.setAttribute("target", "_blank");
              eSocialAnchor.setAttribute("rel", "noopener noreferrer");
              let iconDiv = document.createElement("i");
              iconDiv.setAttribute("class", "fab fa-facebook-f");
              eSocialAnchor.appendChild(iconDiv);
              eSocial.appendChild(eSocialAnchor);
            }
          }
          return eSocial;
          // }
        } else if (value.field == "Employees") {
          return document.createTextNode(this.handleNumberFormatting(params.data[value.field]));
        }
        else if (!params.data[value.field] || params.data[value.field] == "" || params.data[value.field] == "null") {
          return document.createTextNode("-")
        } else {
          return document.createTextNode(params.data[value.field]);
        }
      }.bind(this);
      value.tooltip = t => {
        return t.value;
      };
      return value;
    });
    this.setState({
      cols: data
    });
  }
  //handleFormatting of numbers..............................................................
  handleNumberFormatting = num => {
    if (num) return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else return "-";
  };
  render() {
    return (
      <div className="component-table-grid overflow-hidden">
        <div
          className="ag-theme-balham"
          style={{
            height: this.props.height,
            width: "100%"
          }}
        >
          <AgGridReact
            columnDefs={this.state.cols}
            rowData={this.props.rows}
            enableSorting={true}
            enableColResize={true}
            overlayLoadingTemplate={this.state.overlayLoadingTemplate}
            overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
            frameworkComponents={this.props.frameworkComponents ? this.props.frameworkComponents : null}
          />
        </div>
      </div>
    );
  }
}
export default withRouter(
  connect(({ globalRequest }) => ({ ...globalRequest }))(DataTable)
);
// this.setState({ ShowLoader: props.globalRequestCycleStatus.isProcessing });
