const setFilterList = payload => ({
  type: "SET_FILTER_LIST",
  payload
});
const setMultiselectDependentData = payload => ({
  type: "SET_MULTISELECT_DATA",
  payload
});
const setFilterData = payload => ({
  type: "SET_FILTER_DATA",
  payload
})
const setGccFilterData = payload => ({
  type: "SET_GCC_FILTER_DATA",
  payload
})
export const mutation = {
  setFilterList,
  setMultiselectDependentData,
  setFilterData,
  setGccFilterData
};
