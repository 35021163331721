import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Login from "components/login";
import Company from "components/company";
import PrivateCompany from "components/privateCompany";
import PublicCompany from "components/publicCompany";
import AcquiredCompany from "components/acquiredCompany";
import Investor from "components/investor";
import Person from "components/person";
import Tabular from "components/common/tabular/tabular.js";

// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"
const routes = [
  {
    path: "/login",
    component: Login
  },
  {
    path: "/dashboard/sector",
    component: Company
  },
  {
    path: "/dashboard/map",
    component: Company
  },
  {
    path: "/dashboard/table",
    component: Company
  },
  {
    path: "/company/private",
    component: PrivateCompany
  },
  {
    path: "/company/public",
    component: PublicCompany
  },
  {
    path: "/company/acquired",
    component: AcquiredCompany
  },
  {
    path: "/investor",
    component: Investor
  },
  {
    path: "/Person",
    component: Person
  },
  {
    path: "/dashboard",
    redirect: "/dashboard/map"
  },
  {
    path: "/company",
    redirect: "/company/private"
  },
  {
    path: "/tabular",
    component: Tabular
  },
  {
    path: "/",
    redirect: "/login"
  }
];

const RouteWithSubRoutes = route =>
  route.redirect ? (
    <Route
      exact
      path={route.path}
      render={() => <Redirect to={route.redirect} />}
    />
  ) : (
      <Route
        path={route.path}
        render={props => <route.component {...props} routes={route.routes} />}
      />
    );

const Main = () => (
  <main>
    <Switch>
      {routes.map(route => (
        <RouteWithSubRoutes key={route.path} {...route} />
      ))}
    </Switch>
  </main>
);

export default Main;
