import * as React from "react";
import { AgGridReact } from "ag-grid-react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import emptyImage from "static/images/empty-result.png";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
// import "./sectorTable.scss";
class DataTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            cols: [],
            overlayLoadingTemplate:
                '<span class="ag-overlay-loading-center">Please wait while result is loaded</span>',
            overlayNoRowsTemplate: "No rows to display",
            loading:
                '<span class="ag-overlay-loading-center">Please wait while result is loaded</span>'
        };
    }
    // life cycle method...............................................................
    componentWillReceiveProps(props) {
        if (props.columns !== this.props.columns && props.rows !== this.props.rows) {
            let data = [];
            this.setState({
                rows: props.rows !== undefined && props.rows !== null && props.rows.length ? props.rows : [],
                cols: Object.assign([], props.columns).map((value, index) => {
                    // console.log(value)
                    value.suppressSizeToFit = true;
                    if (value.field !== "Sector") {
                        if (value.field.length <= 5) {
                            value.width = 160;
                        } else if (value.field.length > 5 && value.field.length <= 10) {
                            value.width = 190;
                        } else if (value.field.length > 10 && value.field.length <= 15) {
                            value.width = 220;
                        } else {
                            value.width = 280;
                        }
                        value.comparator = function (value1, value2) {
                            value1 = !value1 ? value1 = { Info: { companyCount: 0 } } : value1;
                            value2 = !value2 ? value2 = { Info: { companyCount: 0 } } : value2;
                            if (value1.Info.companyCount < value2.Info.companyCount) {
                                return -1;
                            } else if (value1.Info.companyCount > value2.Info.companyCount) {
                                return 1;
                            } else {
                                return 0;
                            }
                        }
                    }
                    value.cellRenderer = function (params) {
                        if (params.colDef.headerName == "Sector") {
                            var eDiv = document.createElement("div");
                            var imageTag = document.createElement("img");
                            let base64 = this.fetchImage(params)
                            imageTag.setAttribute("src", base64);
                            imageTag.className = "h-4 w-4";
                            eDiv.className = "flex items-center"
                            eDiv.appendChild(imageTag);
                            var sectorDiv = document.createElement("div");
                            sectorDiv.appendChild(document.createTextNode(params.data.Sector));
                            sectorDiv.className = "ml-2"
                            eDiv.appendChild(sectorDiv);
                            return eDiv;
                        }
                        else {
                            let KeyName = params.colDef.field;
                            if (params.data[KeyName] && params.data[KeyName]["Info"] && params.data[KeyName]["Info"]["GCC-Alert"] && params.data[KeyName]["Info"]["GCC-Alert"] === "Yes") {
                                var eButton = document.createElement('button');
                                eButton.className = "fa fa-bell text-notify float-right mr-6 p-2"
                                eButton.addEventListener('click', (e) => {
                                    e.stopPropagation();
                                    this.props.handleGccItem(Object.assign({}, params.data[KeyName]["Info"], { Sector: params.data.Sector }), null, "SectorView");
                                });
                                var eDiv = document.createElement("div");
                                var countDiv = document.createElement("span");
                                countDiv.appendChild(document.createTextNode(this.handleNumberFormatting(params.data[KeyName]["Info"]["companyCount"])))
                                countDiv.classList.add("text-hero");
                                countDiv.addEventListener('click', (e) => {
                                    e.stopPropagation();
                                    this.props.setFiltersAndNavigate(Object.assign({}, params.data[KeyName]["Info"], { Sector: params.data.Sector }));
                                });
                                countDiv.classList.add("ml-12");
                                eDiv.appendChild(countDiv);
                                eDiv.appendChild(eButton);
                                return eDiv;
                            } else if (params.data[KeyName] && params.data[KeyName]["Info"]) {
                                var countDiv = document.createElement("span");
                                countDiv.classList.add("ml-12");
                                countDiv.classList.add("text-hero");
                                countDiv.appendChild(document.createTextNode(this.handleNumberFormatting(params.data[KeyName]["Info"]["companyCount"])))
                                countDiv.addEventListener('click', (e) => {
                                    e.stopPropagation();
                                    this.props.setFiltersAndNavigate(Object.assign({}, params.data[KeyName]["Info"], { Sector: params.data.Sector }));
                                });
                                return countDiv
                            } else {
                                var countDiv = document.createElement("span");
                                countDiv.classList.add("ml-12");
                                countDiv.classList.add("text-hero");
                                countDiv.appendChild(document.createTextNode("-"))
                                return countDiv;
                            }
                        }
                    }.bind(this);
                    // value.tooltipField = "Company Description";
                    return value
                })
            });
        }
    }
    //handleFormatting of numbers..............................................................
    handleNumberFormatting = num => {
        if (num) return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        else return "-";
    };
    fetchImage = params => {
        let Image = null;
        try {
            Image = require("static/images/" + params.data.Sector + ".png")
        } catch (err) {
            Image = require("static/images/defaultSector.png")
        }
        return Image;
    }
    render() {

        return (
            <div className="component-table-grid overflow-hidden">
                <div
                    className="ag-theme-balham"
                    style={{
                        height: "calc(100vh - 222px)",
                        width: "100%"
                    }}
                >
                    <AgGridReact
                        columnDefs={this.state.cols}
                        rowData={this.state.rows}
                        enableSorting={true}
                        enableColResize={true}
                        overlayLoadingTemplate={this.state.overlayLoadingTemplate}
                        overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                        frameworkComponents={{ "agColumnHeader": this.props.sectorHeader }}
                    />
                </div>
            </div>
        );
    }
}
export default withRouter(
    connect(({ }) => ({}))(DataTable)
);
