import { mutation } from "./mutations";
import { Sector } from "../../services/sector";
import { mutation as requestMutation } from "../globalRequest/mutations";
import { mutation as filterMutations } from "../filter/mutations";
const handleError = err => {
  console.log("Error in company action : ", err);
};
export const requestGetSectorData = (payload) => async dispatch => {
  try {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: true,
        resultFound: false,
        message: null
      })
    );
    let result = await Sector.getSectorData(payload);
    console.log(payload);
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: true,
        message: null
      })
    );
    dispatch(mutation.setSectorData(result));
  } catch (err) {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: false,
        message: null
      })
    );
    handleError(err);
  }
};
export const setFilterData = (payload) => async dispatch => {
  dispatch(filterMutations.setFilterData(payload));
};
export const saveSectorState = (payload) => async dispatch => {
  dispatch(mutation.setSectorState(payload));
};
export const setGccFilterData = (payload) => async dispatch => {
  dispatch(filterMutations.setGccFilterData(payload));
};