import { Api } from "./config/request";

export const getFilteredCompanyList = payload => {
  return Api.postRequest("filteredList?", payload);
};
export const getCompanyDetailById = payload => {
  return Api.postRequest("fetchEntityResult?", payload);
};
export const getCompanyTabData = payload => {
  return Api.postRequest("fetchProfileTabData?", payload);
};
export const getCompanyKeyStatisticsData = payload => {
  return Api.postRequest("fetchKeyStatisticsForProfile?", payload);
};
export const getCompanyKeyRatiosData = payload => {
  return Api.postRequest("fetchKeyRatiosForProfile?", payload);
};
export const getCompanySignalData = payload => {
  return Api.postRequest("fetchSignalForProfile?", payload);
};
export const getCompanyPatentData = payload => {
  return Api.postRequest("fetchPatentsForProfile?", payload);
};
export const getCompanyGccAquisationData = payload => {
  return Api.getRequest("getGCCAcquisitions?permalink=" + payload.permalink);
};
export const getCompanyGccPartnershipsData = payload => {
  return Api.getRequest("getGCCPartnerships?permalink=" + payload.permalink);
};
export const getCompanyGccAffiliationsData = payload => {
  return Api.getRequest("getGCCAffiliations?permalink=" + payload.permalink);
};
export const getCompanyGccOfficesData = payload => {
  return Api.getRequest("getGCCOffices?permalink=" + payload.permalink);
};
//changed reference from GCC Signals method to business signal
export const getCompanyGccSignalsData = payload => {
  return Api.getRequest("getBusinessAlertsForCompany?permalink=" + payload.permalink);
};
//changed reference from GCC Alert method to business signal
export const getGCCAlertData = (payload, filters) => {
  console.log(filters)
  return Api.postRequest("getBusinessSignalsForAlert?" + payload, filters);
}
export const Company = {
  getFilteredCompanyList,
  getCompanyDetailById,
  getCompanyTabData,
  getCompanyKeyRatiosData,
  getCompanyKeyStatisticsData,
  getCompanySignalData,
  getCompanyPatentData,
  getCompanyGccAquisationData,
  getCompanyGccPartnershipsData,
  getCompanyGccAffiliationsData,
  getCompanyGccOfficesData,
  getCompanyGccSignalsData,
  getGCCAlertData
};
