export const setPersonDetail = payload => ({
  type: "SET_PERSON_DETAIL",
  payload
});
export const setPersonTabData = payload => ({
  type: "SET_PERSON_TABULAR_DATA",
  payload
});
export const unsetPersonData = () => ({
  type: "UNSET_PERSON_DATA"
})
export const mutation = {
  setPersonDetail,
  setPersonTabData,
  unsetPersonData
};
