import React, { Component } from "react";
import "./elementBox.scss";
import logoPlaceholder from "static/images/logo-placeholder.png";
export class ImageBox extends Component {
  handleImage = image => {
    image.onerror = "";
    image.src = logoPlaceholder;
    return true;
  };
  render() {
    let props = this.props;
    let imageName =
      props.image.indexOf("http") > -1 ? props.image : "https://" + props.image;
    return (
      <div className="component-image-box border border-rowBorder rounded inline-flex">
        {imageName.indexOf("null") > -1 ? (
          <img
            alt="logo"
            className="img-responsive"
            src={logoPlaceholder}
            style={{ height: props.height, width: props.width }}
          />
        ) : (
          <img
            alt="logo"
            className="img-responsive"
            src={imageName}
            style={{ height: props.height, width: props.width }}
          />
        )}
      </div>
    );
  }
}
export class InfoLabel extends Component {
  render() {
    let props = this.props;
    return (
      <button className="component-info-label rounded-label-hero">
        {props.icon && <i className={props.icon} />}
        <span className="font-fmedium">{props.value || "..."}</span>
      </button>
    );
  }
}
