import { Map } from "../../services/map";
import { mutation } from "./mutations";
import { mutation as requestMutation } from "../globalRequest/mutations";
import { mutation as filterMutations } from "../filter/mutations";
const handleError = err => {
  console.log("Error in map action : ", err);
};
// Actions
export const requestGetCountryLevelData = payload => async dispatch => {
  try {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: true,
        resultFound: false,
        message: null
      })
    );
    let result = await Map.getCountryLevelData(payload);
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: true,
        message: null
      })
    );
    dispatch(mutation.setMapData(result));
  } catch (err) {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: false,
        message: null
      })
    );
    handleError(err);
  }
};

export const requestGetStateLevelData = payload => async dispatch => {
  try {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: true,
        resultFound: false,
        message: null
      })
    );
    let result = await Map.getStateLevelData(payload);
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: true,
        message: null
      })
    );
    dispatch(mutation.setMapData(result));
  } catch (err) {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: false,
        message: null
      })
    );
    handleError(err);
  }
};
export const requestGetCityLevelData = payload => async dispatch => {
  try {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: true,
        resultFound: false,
        message: null
      })
    );
    let result = await Map.getCityLevelData(payload);
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: true,
        message: null
      })
    );
    dispatch(mutation.setMapData(result));
  } catch (err) {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: false,
        message: null
      })
    );
    handleError(err);
  }
};
export const setMapComponentData = payload => async dispatch => {
  dispatch(mutation.setMapComponentData(payload));
};
export const setFilterData = (payload) => async dispatch => {
  dispatch(filterMutations.setFilterData(payload));
};
export const setGccFilterData = (payload) => async dispatch => {
  dispatch(filterMutations.setGccFilterData(payload));
};