import { Search } from "../../services/search";
import { mutation } from "./mutations";

const handleError = err => {
  console.log("Error in search action : ", err);
};

//function to fetch the search result.................................................
export const requestGetSearchResultList = (
  type,
  searchKeyword
) => async dispatch => {
  try {
    let filterObject = { filter: { searchKeyword, type } };
    dispatch(
      mutation.setRequestCycleStatus({
        isProcessing: true,
        resultFound: false,
        message: null
      })
    );
    let result = await Search.getSearchResultList(filterObject);
    dispatch(
      mutation.setRequestCycleStatus({
        isProcessing: false,
        resultFound: false,
        message: null
      })
    );
    dispatch(mutation.setSearchResultList(result));
  } catch (err) {
    dispatch(
      mutation.setRequestCycleStatus({
        isProcessing: false,
        resultFound: false,
        message: null
      })
    );
    handleError(err);
  }
};
