import React, { Component } from "react";
import "./person.scss";
import Tab from "components/common/tab";
// import Table from "components/common/table";
import Overview from "components/common/overview";
// import { InfoLabel } from "components/common/elementBox";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as personActions from "../../store/person/actions";
import searchMethod from "utils/searchMethods";
import ReactPaginate from "react-paginate";
import hardCodedUtils from "../../utils/hardcodedUtils";
import DataTable from "../common/tabular/tabular";
class person extends Component {
  constructor(props) {
    super(props);
    this.state = {
      personData: null,
      currentTab: 1,
      personTableData: null,
      filteredRows: [],
      inputVal: "",
      rows: [],
      pageCount: 0,
      recievedState: props.location.state,
      tabData: [
        { name: "Professional History", id: 1, url: 1 },
        { name: "Academic History", id: 2, url: 2 },
        { name: "Investments(Personal)", id: 3, url: 3 },
        { name: "Investments(Partner)", id: 4, url: 4 }
      ]
    };
  }
  // life cycle methods.......................................................................
  componentWillMount() {
    this.handleComponentMountApi(this.props);
  }
  componentWillUnmount() {
    this.props.unsetAllData();
  }
  componentWillReceiveProps(props) {
    if (this.state.recievedState !== null) {
      if (
        this.state.recievedState.Person[0] !== props.location.state.Person[0] ||
        this.state.recievedState.PersonPermalink[0] !==
        props.location.state.PersonPermalink[0]
      ) {
        this.handleComponentMountApi(props);
        this.setState({ recievedState: props.location.state });
      }
    }
    if (props.personDetail !== null) {
      this.setState({ personData: props.personDetail }, () => {
        this.setState({
          social: {
            facebook:
              this.state.personData.Facebook &&
                this.state.personData.Facebook.val
                ? this.state.personData.Facebook.val
                : null,
            twitter:
              this.state.personData.Twitter && this.state.personData.Twitter.val
                ? this.state.personData.Twitter.val
                : null,
            linkedin:
              this.state.personData.LinkedIn &&
                this.state.personData.LinkedIn.val
                ? this.state.personData.LinkedIn.val
                : null,
            google_plus:
              this.state.personData.GooglePlus &&
                this.state.personData.GooglePlus.val
                ? this.state.personData.GooglePlus.val
                : null,
            website:
              this.state.personData.HomePageURL &&
                this.state.personData.HomePageURL.val
                ? this.state.personData.HomePageURL.val
                : null,
            crunchBase:
              this.state.personData["CB"] && this.state.personData["CB"].val
                ? this.state.personData["CB"].val
                : null
          }
        });
      });
    }
    if (
      props.personTabData !== null &&
      props.personTabData !== undefined &&
      props.personTabData !== ""
    ) {
      this.setState({
        personTableData: props.personTabData,
        pageCount: props.personTabData.rows.length / 50,
        rows: props.personTabData.rows.slice(0, 50),
        filteredRows: props.personTabData.rows.slice(0, 50)
      });
    } else {
      this.setState({
        personTableData: { rows: [], columns: [] },
        rows: []
      });
    }
  }
  //method to be call when components get mounted.....................................
  handleComponentMountApi(props) {
    this.props.unsetAllData();
    Promise.all([
      props.requestGetPersonDetailById({
        id: 59,
        selectedValues: props.location.state
      }),
      props.requestGetTabularDataOfPerson({
        entityType: "Person",
        tabHeading: "1",
        personName: props.location.state.Person[0],
        personPermalink: props.location.state.PersonPermalink[0]
      })
    ]);
  }
  //function to handle the input search ......................................................
  handleInputSearch = text => {
    if (text === "") {
      this.setState({
        investorTableData: this.props.personTabData,
        filteredRows: this.state.rows,
        inputVal: text
      });
    } else {
      let filteredRows = searchMethod.filterArrayWithKey(
        text,
        this.state.rows
      );
      this.setState({
        investorTableData: this.props.personTabData,
        filteredRows,
        inputVal: text
      });
    }
  };
  //function to handle the current selected tab.........................................
  handleSelectedTab = index => {
    this.setState(
      prevState => ({
        currentTab: prevState.tabData[index].url,
        inputVal: "",
        rows: [],
        filteredRows: []
      }),
      () => {
        this.props.requestGetTabularDataOfPerson({
          entityType: "Person",
          tabHeading: this.state.tabData[index].id,
          personName: this.props.location.state.Person[0],
          personPermalink: this.props.location.state.PersonPermalink[0]
        });
      }
    );
  };
  //function to handle the back button........................................................
  handleBackClick = () => {
    this.props.history.goBack();
  };
  //function to handle when a company col is clicked...............................
  handlePersonLink = data => {
    let state = {
      Person: [data.data.Person],
      PersonPermalink: [data.data.PersonPermalink]
    };
    this.props.history.push("/person", state);
  };
  handleCompanyLink = data => {
    let state = {};
    if (data.column.colDef.headerName == "Company" && data.data.Company && data.data.CompanyPermalink && data.data.CompanyType) {
      state = {
        Company: [data.data.Company],
        CompanyPermalink: [data.data.CompanyPermalink]
      };
      this.props.history.push(
        "/company/" + data.data.CompanyType.toLowerCase(),
        state
      );
    } else if (data.column.colDef.headerName == "InvestmentFirm" && data.data.InvestmentFirm && data.data.InvestmentFirmPermalink) {
      state = {
        Investors: [data.data.InvestmentFirm],
        InvestorsPermalink: [data.data.InvestmentFirmPermalink]
      };
      this.props.history.push(
        "/investor",
        state
      );
    }
  };
  //create cols for tabularData .........................................................
  handleConstructionofColumns = cols => {
    let colList = [];
    if (Array.isArray(cols)) {
      colList = cols.map((value, index) => {
        let colObj = { headerName: value.replace(/_/g, " "), field: value };
        if (value == "Person") {
          colObj.onCellClicked = this.handlePersonLink;
          colObj.cellClass = "text-hero";
        } else if (value == "Company" || value == "InvestmentFirm") {
          colObj.onCellClicked = this.handleCompanyLink;
          colObj.cellClass = "text-hero";
        }
        return colObj;
      });
    } else {
      for (let keyName in cols) {
        let colObj = { headerName: cols[keyName], field: keyName };
        if (keyName == "Person") {
          colObj.onCellClicked = this.handlePersonLink;
          colObj.cellClass = "text-hero";
        } else if (keyName == "Company" || keyName == "InvestmentFirm") {
          colObj.onCellClicked = this.handleCompanyLink;
          colObj.cellClass = "text-hero";
        }
        colList.push(colObj);
      }
    }
    return searchMethod.removeItemsFromArray(
      hardCodedUtils.absractTabItems,
      colList
    );
  };
  //function to handle the pagination click......................................................
  handlePageClick = val => {
    let start,
      end = 0;
    if (val.selected == 0) {
      start = 0;
      end = 50;
    } else {
      start = 50 * val.selected;
      end = 50 * val.selected + 50;
    }
    let rows = this.state.personTableData.rows.slice(start, end);
    this.setState({ inputVal: "", rows, filteredRows: rows });
  };
  //render function .......................................................................
  render() {
    let state = this.state;
    let props = this.props;
    return (
      <div className="component-person view">
        <div className="overview">
          <div className="overview-header flex justify-between items-center mb-2">
            <div
              className="text-xs cursor-pointer font-fmedium"
              onClick={() => this.handleBackClick()}
            >
              <i className="fa fa-caret-left" /> BACK
            </div>
            <div>
              {state.personData && (
                <div className="contact-info-container">
                  {/* <InfoLabel icon={"fas fa-phone-volume text-xs mr-1"} value={"jk"} />
                  <InfoLabel icon={"fas fa-envelope text-xs mr-1"} value={"jk"} /> */}
                  {/* <InfoLabel
                    icon={"fa fa-map-marker-alt mr-1 text-xs"}
                    value={
                      state.personData.Headquarter !== undefined
                        ? state.personData.Headquarter.val
                        : ""
                    }
                  /> */}
                </div>
              )}
            </div>
          </div>
          <div className="profile-info-container">
            <div className="profile-detail-container">
              <div className="company-name">
                {this.props.location.state.Person[0]}
              </div>
              <div className="w-full">
                <table className="info-table -ml-4">
                  <thead>
                    {state.personData !== null ? (
                      <tr>
                        <th>
                          {state.personData.PrimaryRole
                            ? state.personData.PrimaryRole.val
                            : "-"}
                        </th>
                        <th>
                          {state.personData.PrimaryOrganization
                            ? state.personData.PrimaryOrganization.val
                            : "-"}
                        </th>
                        <th>
                          {state.personData.PersonalInvestments
                            ? state.personData.PersonalInvestments.val
                            : "-"}
                        </th>
                        <th>
                          {state.personData.PartnerInvestments
                            ? state.personData.PartnerInvestments.val
                            : "-"}
                        </th>
                      </tr>
                    ) : null}
                  </thead>
                  <tbody>
                    <tr className="font-fmedium">
                      <td>Primary Role </td>
                      <td>Primary Organization </td>
                      <td>Investments (Personal) </td>
                      <td>Investments(Partner) </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <Overview
            overview={
              state.personData && state.personData.Bio
                ? state.personData.Bio.val
                : state.overview
            }
            social={state.social}
          />
        </div>
        <Tab
          handleButtonClick={value => this.handleSelectedTab(value)}
          tabList={state.tabData}
          activeTab={state.currentTab}
        />
        {props.location.pathname === "/person" ? (
          state.personTableData !== null ? (
            <div className="mt-4">
              <input
                className="profile-search-bar"
                value={this.state.inputVal}
                placeholder="Search here"
                type="text"
                onChange={e => this.handleInputSearch(e.target.value)}
              />
              <DataTable
                rows={state.filteredRows}
                columns={this.handleConstructionofColumns(
                  state.personTableData.columns
                )}
                height={"600px"}
              />
            </div>
          ) : null
        ) : null}
        {this.state.rows.length && this.state.personTableData && this.state.personTableData.rows.length > 50 ? (
          <div className="company-paginate">
            <ReactPaginate
              previousLabel={<i className="fa fa-chevron-left" />}
              nextLabel={<i className="fa fa-chevron-right" />}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        ) : null}
      </div>
    );
  }
}
export default connect(
  ({ person }) => ({ ...person }),
  dispatch => bindActionCreators({ ...personActions }, dispatch)
)(person);
