import React, { Component } from "react";
import "./selectedFilter.scss";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export default class SelectedFilterList extends Component {
  constructor(props) {
    super(props);
    this.filterList = [];
  }
  //life cycle methods...........................................................................
  componentWillReceiveProps(props) {
    this.setState({
      filterList: props.appliedFilters,
      multiSelectData: props.multiSelectData
    });
  }
  //function to handle the dependent filter.....................................................
  handleDependentFilter = (filterItem, index) => {
    switch (filterItem.display) {
      case "DropDown":
        let dependentItemArray = Array.isArray(filterItem.dependentFilter)
          ? filterItem.dependentFilter
          : filterItem.dependentFilter.split(",");
        if (typeof dependentItemArray[0] === "string") {
          let dependentItem = dependentItemArray.map(item => {
            return {
              value: item,
              label: item
            };
          });
          filterItem.dependentFilter = dependentItem;
        }
        filterItem.selectedDependentFilter = filterItem.selectedDependentFilter
          ? filterItem.selectedDependentFilter
          : filterItem.dependentFilter[0];
        return (
          <div className="mr-2 subfilter-dropdown w-1/5">
            <Select
              value={filterItem.selectedDependentFilter}
              onChange={e => this.props.handleDependentFilter(e, index)}
              options={filterItem.dependentFilter}
            />
          </div>
        );
      case "TextBox":
        filterItem.selectedDependentFilter = filterItem.selectedDependentFilter
          ? filterItem.selectedDependentFilter
          : "";
        if (filterItem.selectedSubFilter.value !== "between") {
          return (
            <div className="input-box ">
              <input
                type="text"
                value={filterItem.selectedDependentFilter}
                onChange={e =>
                  this.props.handleDependentFilter(e.target.value, index)
                }
                placeholder={"Value"}
              />
            </div>
          );
        } else {
          filterItem.selectedDependentFilter2 = filterItem.selectedDependentFilter2
            ? filterItem.selectedDependentFilter2
            : "";
          return (
            <div className="range-box flex">
              <div className="input-box">
                <input
                  type="text"
                  value={filterItem.selectedDependentFilter}
                  onChange={e =>
                    this.props.handleDependentFilter(e.target.value, index)
                  }
                  placeholder={"From"}
                />
              </div>
              <div className="input-box">
                <input
                  type="text"
                  value={filterItem.selectedDependentFilter2}
                  onChange={e =>
                    this.props.handleDependentFilter(
                      e.target.value,
                      index,
                      "between"
                    )
                  }
                  placeholder={"To"}
                />
              </div>
            </div>
          );
        }

      case "DatePicker":
        filterItem.selectedDependentFilter = filterItem.selectedDependentFilter
          ? filterItem.selectedDependentFilter
          : new Date();
        if (filterItem.selectedSubFilter.value !== "between") {
          return (
            <DatePicker
              selected={filterItem.selectedDependentFilter}
              onChange={e => this.props.handleDependentFilter(e, index)}
            />
          );
        } else {
          filterItem.selectedDependentFilter2 = filterItem.selectedDependentFilter2
            ? filterItem.selectedDependentFilter2
            : new Date();
          return (
            <div className="range-box">
              <DatePicker
                selected={filterItem.selectedDependentFilter}
                onChange={e => this.props.handleDependentFilter(e, index)}
              />
              <DatePicker
                selected={filterItem.selectedDependentFilter2}
                onChange={e =>
                  this.props.handleDependentFilter(e, index, "between")
                }
              />
            </div>
          );
        }
      case "MultiSelect":
        if (filterItem.asynchronous === true) {
          filterItem.selectedDependentFilter = filterItem.selectedDependentFilter
            ? filterItem.selectedDependentFilter
            : [];
          filterItem.options =
            this.props.multiSelectData && this.props.multiSelectData.length
              ? this.props.multiSelectData.map((value, index) => {
                return {
                  value: value,
                  label: value
                };
              })
              : [];
          return (
            <div className="mr-2 subfilter-dropdown flex-1">
              <Select
                value={filterItem.selectedDependentFilter}
                onClick={this.handleClick}
                onChange={e => this.props.handleDependentFilter(e, index)}
                options={filterItem.options}
                isMulti={true}
                isSearchable={true}
                className="async-multi-select"
                placeholder="Search here..."
                onBlur={this.props.handleAsyncMenu}
                onInputChange={e =>
                  this.props.handleMulltiSelectInput(e, index, filterItem)
                }
              />
            </div>
          );
        } else {
          let dependentItemArray = Array.isArray(filterItem.dependentFilter)
            ? filterItem.dependentFilter
            : filterItem.dependentFilter.split(",");
          if (typeof dependentItemArray[0] === "string") {
            let dependentItem = dependentItemArray.map(item => {
              return {
                value: item,
                label: item
              };
            });
            filterItem.dependentFilter = dependentItem;
          }
          filterItem.selectedDependentFilter = filterItem.selectedDependentFilter
            ? filterItem.selectedDependentFilter
            : [filterItem.dependentFilter[0]];
          return (
            <div className="mr-2 subfilter-dropdown">
              <Select
                value={filterItem.selectedDependentFilter}
                onChange={e => this.props.handleDependentFilter(e, index)}
                isMulti={true}
                closeMenuOnSelect={false}
                options={filterItem.dependentFilter}
              />
            </div>
          );
        }
      default:
        return null;
    }
  };
  //render function to display thee component...........................................................
  render() {
    console.log(this.props)
    return (
      <div className="applied-filters-container text-xs">
        {this.props.appliedFilters.map((filterItem, index) => {
          return (
            <div className="filter-content flex items-center mt-1">
              <div className="filter-name bg-rowBorder mr-2 text-body-text font-fmedium px-4 py-2 bg-white rounded border border-rowBorder">
                {filterItem.filterName}
              </div>
              <div className="mr-2 subfilter-dropdown ">
                <Select
                  value={filterItem.selectedSubFilter}
                  onChange={e => this.props.handleSubFilter(e, index)}
                  options={filterItem.subFilters}
                />
              </div>
              {this.handleDependentFilter(filterItem, index)}
              <div className="rmv-btn">
                <div
                  className="rounded-full h-6 w-6 flex-center bg-divider cursor-pointer hover:bg-hero hover:text-white"
                  onClick={() => this.props.handleRemoveSelectedFilter(index)}
                >
                  <i className="fa fa-times" />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
