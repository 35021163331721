import { Company } from "../../services/company";
import { mutation } from "./mutations";
import { mutation as requestMutation } from "../globalRequest/mutations";
import { mutation as filterMutations } from "../filter/mutations";
import {
  requestGetFilterList,
  requestGetMultiselectDependentData
} from "../filter/actions";
const handleError = err => {
  console.log("Error in company action : ", err);
};

// Actions
export const fetchFilterList = requestGetFilterList;
export const fetchMultiselectDependentData = requestGetMultiselectDependentData;
export const requestGetFilteredCompanyList = payload => async dispatch => {
  try {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: true,
        resultFound: false,
        message: null
      })
    );
    let result = await Company.getFilteredCompanyList(payload);
    dispatch(mutation.setCompanyList(result));
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: true,
        message: null
      })
    );
  } catch (err) {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: false,
        message: null
      })
    );
    handleError(err);
  }
};
export const setExplicitGlobalStatus = () => async dispatch => {
  dispatch(
    requestMutation.setGlobalRequest({
      isProcessing: true,
      resultFound: false,
      message: null
    })
  );
};

export const requestGetCompanyDetailById = id => async dispatch => {
  try {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: true,
        resultFound: false,
        message: null
      })
    );
    let result = await Company.getCompanyDetailById(id);
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: true,
        message: null
      })
    );
    dispatch(mutation.setCompanyDetail(result));
  } catch (err) {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: false,
        message: null
      })
    );
    handleError(err);
  }
};
export const requestGetTabularDataOfCompany = params => async dispatch => {
  try {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: true,
        resultFound: false,
        message: null
      })
    );
    let result = await Company.getCompanyTabData(params);
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: true,
        message: null
      })
    );
    dispatch(mutation.setCompanyTabData(result));
  } catch (err) {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: false,
        message: null
      })
    );
    handleError(err);
  }
};
export const requestGetCompanyKeyStaticsData = params => async dispatch => {
  try {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: true,
        resultFound: false,
        message: null
      })
    );
    let result = await Company.getCompanyKeyStatisticsData(params);
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: true,
        message: null
      })
    );
    dispatch(mutation.setCompanyTabData(result));
  } catch (err) {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: false,
        message: null
      })
    );
    handleError(err);
  }
};
export const requestGetCompanyKeyRatiosData = params => async dispatch => {
  try {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: true,
        resultFound: false,
        message: null
      })
    );
    let result = await Company.getCompanyKeyRatiosData(params);
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: true,
        message: null
      })
    );
    dispatch(mutation.setCompanyTabData(result));
  } catch (err) {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: false,
        message: null
      })
    );
    handleError(err);
  }
};
export const requestGetCompanyPatentsData = params => async dispatch => {
  try {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: true,
        resultFound: false,
        message: null
      })
    );
    let result = await Company.getCompanyPatentData(params);
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: true,
        message: null
      })
    );
    dispatch(mutation.setCompanyTabData(result));
  } catch (err) {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: false,
        message: null
      })
    );
    handleError(err);
  }
};
export const requestGetCompanySignalsData = params => async dispatch => {
  try {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: true,
        resultFound: false,
        message: null
      })
    );
    let result = await Company.getCompanySignalData(params);
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: true,
        message: null
      })
    );
    dispatch(mutation.setCompanyTabData(result));
  } catch (err) {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: false,
        message: null
      })
    );
    handleError(err);
  }
};
export const requestGetCompanyGccAquisationData = params => async dispatch => {
  try {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: true,
        resultFound: false,
        message: null
      })
    );
    let result = await Company.getCompanyGccAquisationData(params);
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: true,
        message: null
      })
    );
    dispatch(mutation.setCompanyTabData(result));
  } catch (err) {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: false,
        message: null
      })
    );
    handleError(err);
  }
};
export const requestGetCompanyGccOfficesData = params => async dispatch => {
  try {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: true,
        resultFound: false,
        message: null
      })
    );
    let result = await Company.getCompanyGccOfficesData(params);
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: true,
        message: null
      })
    );
    dispatch(mutation.setCompanyTabData(result));
  } catch (err) {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: false,
        message: null
      })
    );
    handleError(err);
  }
};
export const requestGetCompanyGccPartnershipsData = params => async dispatch => {
  try {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: true,
        resultFound: false,
        message: null
      })
    );
    let result = await Company.getCompanyGccPartnershipsData(params);
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: true,
        message: null
      })
    );
    dispatch(mutation.setCompanyTabData(result));
  } catch (err) {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: false,
        message: null
      })
    );
    handleError(err);
  }
};
export const requestGetCompanyGccAffiliationsData = params => async dispatch => {
  try {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: true,
        resultFound: false,
        message: null
      })
    );
    let result = await Company.getCompanyGccAffiliationsData(params);
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: true,
        message: null
      })
    );
    dispatch(mutation.setCompanyTabData(result));
  } catch (err) {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: false,
        message: null
      })
    );
    handleError(err);
  }
};
export const requestGetCompanyGccSignalsData = params => async dispatch => {
  // debugger;
  try {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: true,
        resultFound: false,
        message: null
      })
    );
    let result = await Company.getCompanyGccSignalsData(params);
    // debugger;
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: true,
        message: null
      })
    );
    dispatch(mutation.setGCCAlertData(result));
    dispatch(mutation.setCompanyTabData(result));
  } catch (err) {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: false,
        message: null
      })
    );
    handleError(err);
  }
};
export const requestGetGccAlertNews = (payload, filters) => async dispatch => {
  try {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: true,
        resultFound: false,
        message: null
      })
    );
    let result = await Company.getGCCAlertData(payload, filters);
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: true,
        message: null
      })
    );
    dispatch(mutation.setGCCAlertData(result));
  } catch (err) {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: false,
        message: null
      })
    );
    handleError(err);
  }
};
export const requestGetGccAllAlertNews = (payload, filters) => async dispatch => {
  try {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: true,
        resultFound: false,
        message: null
      })
    );
    let result = await Company.getCompanySignalData(payload, filters);
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: true,
        message: null
      })
    );
    dispatch(mutation.setGCCAllAlertData(result));
  } catch (err) {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: false,
        message: null
      })
    );
    handleError(err);
  }
};
export const storeUserAppliedFilters = params => async dispatch => {
  dispatch(mutation.setAppliedFilters(params));
};
export const storeUserAppliedColumns = params => async dispatch => {
  dispatch(mutation.setAppliedColumns(params));
};
export const unsetAllData = () => async dispatch => {
  dispatch(mutation.unsetCompanyData());
};
export const setFilterData = (payload) => async dispatch => {
  dispatch(filterMutations.setFilterData(payload));
};
export const setGccFilterData = (payload) => async dispatch => {
  dispatch(filterMutations.setGccFilterData(payload));
};
export const requestAllAlertAndGccNewsData = (payload1, payload2, filters) => async dispatch => {
  dispatch(
    requestMutation.setGlobalRequest({
      isProcessing: true,
      resultFound: false,
      message: null
    }));
  Promise.all([Company.getCompanyGccSignalsData(payload1), Company.getCompanySignalData(payload2, filters)]).then((response) => {
    dispatch(mutation.setGCCAlertData(response[0]));
    dispatch(mutation.setGCCAllAlertData(response[1]));
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: true,
        message: null
      }));
  }, () => {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: true,
        message: null
      }));
  });
}