import React, { Component } from "react";
import "./gccNews.scss";
import EmptyContainer from "static/images/empty-result.png";
export default class GccNews extends Component {
  handleGccTitleClick = obj => {
    window.open(obj.url);
  };

  render() {
    return (
      <div className={this.props.containerClass + " overflow-hidden rounded shadow absolute z-10 rounded bg-white"}>
        <div className="container-heading bg-white border-b border-rowBorder absolute pin-t pin-l w-full py-2 px-3 flex justify-between items-center">
          <div className="gcc-label">Alerts</div>
          <div
            className="close-btn cursor-pointer hover:text-hero"
            onClick={() => this.props.handleGccAlertBool(0)}
          >
            <i className="fa fa-times" />
          </div>
        </div>
        <div className="news-container text-gcc overflow-auto mt-10 px-3 pb-3 font-fmedium">
          {this.props.gccData.rows && this.props.gccData.rows.length ? (
            this.props.gccData.rows.map((row, index) => {
              return (
                <div className="gcc-news-section mt-1 py-2 px-3 bg-bg border border-hero-light">
                  <div className="heading-section">
                    <div className="name-title-section text-xs font-fmedium flex-between">
                      <div className="name-section alert-company-name text-xs text-white px-3 py-2 rounded-full">
                        {row.name}
                      </div>
                      <div className="date-section">{row.date}</div>
                    </div>
                    <div className="name-section mt-3 text-xs">
                      {row.signal}
                    </div>

                    <div
                      className="title-section text-sm mb-3 mt-3 cursor-pointer "
                      onClick={() => this.handleGccTitleClick(row)}
                    >
                      {row.title}
                    </div>
                  </div>
                  <div className="snippet-section opacity-50 text-xs">
                    {row.snippet}
                  </div>
                </div>
              );
            })
          ) : (
              <div className="empty-container flex justify-center p-10 items-center">
                <img
                  className="img-responsive"
                  style={{ height: "50px" }}
                  src={EmptyContainer}
                  alt="No Data"
                />
              </div>
            )}
        </div>
      </div>
    );
  }
}
