import { Api } from "./config/request";
export const getFilterList = payload => {
  return Api.getRequest("fetchFilterList?");
};
export const getMultiselectDependentData = (id, payload) => {
  return Api.postRequest("getDependentFilterList?" + id, payload);
}
export const Filter = {
  getFilterList,
  getMultiselectDependentData
};
