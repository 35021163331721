import { Api } from "./config/request";

export const getPersonDetailById = payload => {
    return Api.postRequest("fetchEntityResult?", payload);
};
export const getPersonTabData = payload => {
    return Api.postRequest("fetchProfileTabData?", payload);
};
export const Person = {
    getPersonDetailById,
    getPersonTabData,
};
