import { initialState } from "./states";

export const person = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PERSON_DETAIL": {
      return Object.assign({}, state, {
        personDetail: action.payload
      });
    }
    case "SET_PERSON_TABULAR_DATA": {
      return Object.assign({}, state, {
        personTabData: action.payload
      });
    }
    case "UNSET_PERSON_DATA": {
      return Object.assign({}, state, {
        personDetail: null,
        personTabData: null
      });
    }
    default:
      return state;
  }
};
