import { initialState } from "./states";

export const company = (state = initialState, action) => {
  switch (action.type) {
    case "SET_COMPANY_LIST": {
      return Object.assign({}, state, {
        companyList: action.payload
      });
    }
    case "SET_COMPANY_DETAIL": {
      return Object.assign({}, state, {
        companyDetail: action.payload
      });
    }
    case "SET_COMPANY_TAB_DATA": {
      return Object.assign({}, state, {
        companyTabData: action.payload
      });
    }
    case "SET_GCC_ALERT_DATA": {
      return Object.assign({}, state, {
        gccAlertData: action.payload
      });
    }
    case "SET_APPLIED_FILTERS": {
      return Object.assign({}, state, {
        appliedFilters: action.payload
      });
    }
    case "SET_APPLIED_COLUMNS": {
      return Object.assign({}, state, {
        appliedColumns: action.payload
      });
    }
    case "SET_GCC_ALL_ALERT_DATA": {
      return Object.assign({}, state, {
        gccAllAlertData: action.payload
      });
    }
    case "UNSET_COMPANY_DATA": {
      return Object.assign({}, state, {
        companyTabData: null,
        companyDetail: null,
        companyList: []
      });
    }
    default:
      return state;
  }
};
