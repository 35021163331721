import { initialState } from "./states";
export const sector = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SECTOR_DATA": {
      return Object.assign({}, state, {
        sectorData: action.payload
      });
    } case "SET_SECTOR_STATE": {
      return Object.assign({}, state, {
        sectorState: action.payload
      });
    }
    default:
      return state;
  }
};
