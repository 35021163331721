import React, { Component } from "react";
import "./header.scss";
import Icon from "static/images/user-icon.png";
// import { ImageBox } from "components/common/elementBox";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LoaderImage from "static/images/waiting.gif";
import * as searchActions from "store/search/actions";
import { withRouter } from "react-router-dom";
class header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerText: "All",
      searchText: "",
      autocompleteVisible: false,
      searchHeader: ["All", "Company", "Investor", "Person", "Industry"],
      list: []
    };
    this.autocompleteRef = React.createRef();
  }
  //life cycle method.................................................................
  componentWillMount() {
    document.addEventListener("mousedown", this.handleInsideClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleInsideClick, false);
  }
  handleInsideClick = e => {
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.handleOutsideClick();
  };
  handleOutsideClick = () => {
    this.setState({
      autocompleteVisible: false
    });
  };
  componentDidMount() {
    this.autocompleteRef.current.onfocus = function () {
      this.setState({ autocompleteVisible: true });
    }.bind(this);
  }
  componentWillReceiveProps(props) {
    let list =
      props.searchResultList && props.searchResultList.length
        ? props.searchResultList.slice(0, 5)
        : [];
    this.setState({
      list: this.StructureList(list)
    });
  }
  //function to structure the list.................................................
  StructureList = list => {
    let stucturedList = [];
    for (let i = 0; i < list.length; i++) {
      let str = list[i];
      let listObj = {
        name: null,
        id: null,
        info: null,
        type: null,
        entityType: null,
        img: null
      };
      //level decides on val 1 it will be name,level 2 decides it will be type,3rd will be id,4th is the info of that type
      let level = 1;
      let dynamicstring = "";
      for (let j = 0; j < str.length; j++) {
        if (str[j] === "#") {
          if (level === 1) {
            listObj.name = dynamicstring;
          } else if (level === 2) {
            listObj.type = dynamicstring;
          } else if (level === 3) {
            listObj.id = dynamicstring;
          } else if (level === 4) listObj.entityType = dynamicstring;
          else if (level === 5) listObj.img = dynamicstring;
          level = level + 1;
          dynamicstring = "";
          for (var k = j; k < str.length; k++) {
            if (str[k] !== "#") {
              j = k - 1;
              break;
            }
          }
        } else {
          dynamicstring = dynamicstring + str[j];
          if (j + 1 === str.length);
          listObj.info = dynamicstring;
        }
      }
      stucturedList.push(listObj);
    }
    //console.log(stucturedList);
    return stucturedList;
  };
  //when user taps on a tab.........................................................
  handleActiveSearchTab = (header, event) => {
    this.setState(
      {
        headerText: header
      },
      () => {
        this.props.requestGetSearchResultList(
          this.state.headerText,
          this.state.searchText
        );
      }
    );
  };
  //function is called when user types something on the input field.......................
  handleSearchText = e => {
    this.setState(
      {
        searchText: e.target.value
      },
      function () {
        if (this.autoTimeout !== undefined) {
          clearTimeout(this.autoTimeout);
          this.autoTimeout = undefined;
        }
        this.autoTimeout = setTimeout(() => {
          // this.props.fetchList(this.state.headerText, this.state.searchText);
          this.props.requestGetSearchResultList(
            this.state.headerText,
            this.state.searchText
          );
        }, 700);
      }
    );
  };
  //function is called when user clicks on a particular item...........................
  handleItemSelect = item => {
    this.setState({ autocompleteVisible: false });
    if (item.type === "Company") {
      let state = { Company: [item.name], CompanyPermalink: [item.id] };
      let companyType = item.entityType.toLowerCase();
      if (companyType == "subsidiary") {
        companyType = "private";
      }
      this.props.history.push(
        "/company/" + companyType,
        state
      );
    } else if (item.type === "Person") {
      let state = { Person: [item.name], PersonPermalink: [item.id] };
      this.props.history.push("/person", state);
    } else if (item.type == "Investor") {
      let state = { Investors: [item.name], InvestorsPermalink: [item.id] };
      this.props.history.push("/investor", state);
    }
  };
  // render function................................................................
  render() {
    return (
      <header className="component-header bg-hero py-4 px-8">
        <div className="flex-between">
          <div className="title-container flex-start text-white">
            <div className="bg-white bar h-8" />
            <div
              className="ml-2 w-1/2 pr-8 font-fmedium tracking-wide"
              onClick={() => {
                this.props.history.push("/dashboard/map");
              }}
            >
              United Information Center
            </div>
          </div>
          <div
            ref={node => (this.node = node)}
            className="search-bar-container"
          >
            <div className="relative">
              <span className="opacity-50 absolute h-full text-white flex justify-center items-center px-4">
                <i className="fa fa-search" />
              </span>

              <div>
                <input
                  value={this.state.searchText}
                  onChange={e => this.handleSearchText(e)}
                  className="theme-input text-white white px-4 py-2  w-full pl-12"
                  type="text"
                  placeholder="Search for Companies, people, investors & industry"
                  ref={this.autocompleteRef}
                />
              </div>
              {this.state.searchText.length &&
                this.state.autocompleteVisible ? (
                  <div className="search-result-container shadow rounded overflow-hidden h-full bg-white absolute pin-t pin-l w-full mt-12">
                    {this.state.list !== undefined ? (
                      <div className="search-header theme-card bg-white absolute pin-t pin-l w-full border-b border-divider">
                        {this.state.searchHeader.map((header, index) => (
                          <button
                            key={index}
                            className={
                              this.state.headerText === header
                                ? "rounded-btn px-6 mr-2 active"
                                : "rounded-btn  px-6 mr-2"
                            }
                            onClick={event =>
                              this.handleActiveSearchTab(header, event)
                            }
                          >
                            {header}
                          </button>
                        ))}
                      </div>
                    ) : null}
                    <div className="result mt-16">
                      {!this.props.requestCycleStatus.isProcessing ? (
                        this.state.list.map((data, index) => (
                          <div
                            key={index}
                            className="theme-card border-rowBorder border-b hover:bg-hero-light  flex-start cursor-pointer"
                            onClick={() => {
                              this.handleItemSelect(data);
                            }}
                          >
                            <div className="flex w-full">
                              {/* <div className="image-container inline-block">
                                <ImageBox
                                  image={"https://" + data.img}
                                  height={40}
                                />
                              </div> */}

                              <div className="info-container inline-block">
                                <div className="text-sm text-black font-fmedium mb-2">
                                  {data.name}
                                </div>
                                <div className="text-xs text-black font-fnormal tracking-wide leading-tight">
                                  <span className="Italic font-fmedium">{data.type}</span>
                                  {data.info.length > 150
                                    ? (': ' + data.info.slice(0, 150) + "...")
                                    : (": " + data.info)}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                          <div className="active autcomplete-loader">
                            <img
                              className="img-fluid my-6"
                              src={LoaderImage}
                              alt="loader"
                              style={{ height: "40px" }}
                            />
                          </div>
                        )}
                    </div>
                  </div>
                ) : null}
            </div>
          </div>
          <div className="icon-container  flex-end">
            <img className="img-fluid h-10" src={Icon} alt="icon" />
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter(
  connect(
    ({ search }) => ({ ...search }),
    dispatch => bindActionCreators({ ...searchActions }, dispatch)
  )(header)
);
