export const setMapData = payload => ({
  type: "SET_MAP_DATA",
  payload
});
export const setMapComponentData = payload => ({
  type: "SET_MAP_COMPONENT_DATA",
  payload
});
export const mutation = {
  setMapData,
  setMapComponentData
};