import React, { Component } from "react";
import Router from "./router";
import { withRouter } from "react-router-dom";
import LoaderImage from "static/images/loader.svg";
// import ReactModal from "react-modal";
import Header from "components/common/header/index.js";
import "./App.scss";
import { connect } from "react-redux";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { ShowLoader: false, overflow: false };
  }
  //life cycle method................................................................
  componentWillReceiveProps(props) {
    this.setState({
      ShowLoader: props.globalRequestCycleStatus.isProcessing,
      overflow: props.isOverflow
    });
  }

  //render function.........................................................................
  render() {
    return (
      <div
        className={
          this.state.overflow ? "App overflow-hidden" : "App overflow-auto"
        }
      >
        {this.props.location.pathname === "/login" ? null : <Header />}
        <Router />
        <div className={this.state.ShowLoader ? "backdrop active" : "backdrop"}>
          <img className="img-fluid" src={LoaderImage} alt="loader" />
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(({ globalRequest }) => ({ ...globalRequest }))(App)
);
