const setCompanyList = payload => ({
  type: "SET_COMPANY_LIST",
  payload
});

export const setCompanyDetail = payload => ({
  type: "SET_COMPANY_DETAIL",
  payload
});
export const setCompanyTabData = payload => ({
  type: "SET_COMPANY_TAB_DATA",
  payload
});
export const setGCCAlertData = payload => ({
  type: "SET_GCC_ALERT_DATA",
  payload
});
export const setGCCAllAlertData = payload => ({
  type: "SET_GCC_ALL_ALERT_DATA",
  payload
})
export const setAppliedFilters = payload => ({
  type: "SET_APPLIED_FILTERS",
  payload
});
export const setAppliedColumns = payload => ({
  type: "SET_APPLIED_COLUMNS",
  payload
});
export const unsetCompanyData = () => ({
  type: "UNSET_COMPANY_DATA"
});
export const mutation = {
  setCompanyList,
  setCompanyDetail,
  setCompanyTabData,
  setGCCAlertData,
  setAppliedFilters,
  setAppliedColumns,
  unsetCompanyData,
  setGCCAllAlertData
};
