const setSearchResultList = data => ({
  type: "SET_SEARCH_RESULT_LIST",
  data
});

const setRequestCycleStatus = data => ({
  type: "SET_REQUEST_CYCLE_STATUS",
  data
});

export const mutation = {
  setSearchResultList,
  setRequestCycleStatus
};
