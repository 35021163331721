import { Person } from "../../services/person";
import { mutation } from "./mutations.js";
import { mutation as requestMutation } from "../globalRequest/mutations";
const handleError = err => {
  console.log("Error in Person action : ", err);
};
// Actions.......................................................................
export const requestGetPersonDetailById = id => async dispatch => {
  try {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: true,
        resultFound: false,
        message: null
      })
    );
    let result = await Person.getPersonDetailById(id);
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: true,
        message: null
      })
    );
    dispatch(mutation.setPersonDetail(result));
  } catch (err) {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: false,
        message: null
      })
    );
    handleError(err);
  }
};
export const requestGetTabularDataOfPerson = params => async dispatch => {
  try {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: true,
        resultFound: false,
        message: null
      })
    );
    let result = await Person.getPersonTabData(params);
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: true,
        message: null
      })
    );
    dispatch(mutation.setPersonTabData(result));
  } catch (err) {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: false,
        message: null
      })
    );
    handleError(err);
  }
};
export const unsetAllData = () => async dispatch => {
  dispatch(mutation.unsetPersonData());
}
