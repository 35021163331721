import { mutation } from "./mutations";
import { mutation as requestMutation } from "../globalRequest/mutations";
import { Filter } from "../../services/filter";
const handleError = err => {
  console.log("Error in company action : ", err);
};
export const requestGetFilterList = payload => async dispatch => {
  try {
    let result = await Filter.getFilterList(payload);
    dispatch(mutation.setFilterList(result));
  } catch (err) {
    handleError(err);
  }
};
export const requestGetMultiselectDependentData = (
  id,
  payload
) => async dispatch => {
  try {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: true,
        resultFound: false,
        message: null
      })
    );
    let result = await Filter.getMultiselectDependentData(id, payload);
    console.log(id, payload);
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: true,
        message: null
      })
    );
    dispatch(mutation.setMultiselectDependentData(result));
  } catch (err) {
    dispatch(
      requestMutation.setGlobalRequest({
        isProcessing: false,
        resultFound: false,
        message: null
      })
    );
    handleError(err);
  }
};
export const setFilterData = (payload) => async dispatch => {
  dispatch(mutation.setFilterData(payload));
};
export const setGccFilterData = (payload) => async dispatch => {
  dispatch(mutation.setGccFilterData(payload));
};