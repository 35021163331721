import React, { Component } from "react";
import "./filterList.scss";
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
export default class FilterListComponent extends Component {
  //function to handle the selected item .........................................
  handleIsItemSelected = () => {
    return false;
  };

  //render function to display thee component.....................................
  render() {
    return (
      <div>
        <div className="relative header-container flex-between font-bold border-b border-rowBorder px-4 py-3 text-sm absolute bg-white w-full pin-t pin-l">
          <span>Edit Filters</span>
          <span
            onClick={() => this.props.handleFilterBool(0)}
            className="cursor-pointer flex flex-center px-3  absolute pin-r pin-t h-full hover:text-hero"
          >
            <i className="fa fa-times mr-1" />
          </span>
        </div>
        <Accordion className="component-accordion-dropdown">
          {this.props.recievedFilters.map((parentItem, parentIndex) => {
            return (
              <AccordionItem
                key={parentIndex}
                className="accordion-title-container"
              >
                <AccordionItemTitle className="accordion-title font-normal text-xs font-fmedium cursor-pointer bg-white text-xs py-2 px-3">
                  <h3 className="u-position-relative">
                    {parentItem.filterHeader}
                    <div className="accordion__arrow" role="presentation" />
                  </h3>
                </AccordionItemTitle>
                <AccordionItemBody className="filter-item-body accordion-body bg-bg">
                  {parentItem.filters.map((childItem, childIndex) => {
                    return (
                      <div
                        className="filter-name text-xxs px-3 py-2 cursor-pointer font-fmedium hover:bg-hero-light"
                        onClick={() =>
                          this.props.handleFilterItemClick(
                            parentItem,
                            childItem
                          )
                        }
                      >
                        {childItem.filterName}
                      </div>
                    );
                  })}
                </AccordionItemBody>
              </AccordionItem>
            );
          })}
        </Accordion>
      </div>
    );
  }
}
