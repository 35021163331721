export const initialState = {
  globalRequestCycleStatus: {
    isProcessing: false,
    resultFound: false,
    message: null
  },
  backdropActive: {
    value: false
  },
  modalComponent: null,
  isOverflow: false
};
