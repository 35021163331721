import React, { Component } from 'react';
import "./sectorHeader.scss";
export default class CustomHeader extends Component {
  constructor(props) {
    super(props);

    props.reactContainer.style.display = "inline-block";

    this.state = {
      ascSort: 'disabled',
      descSort: 'disabled',
      noSort: 'disabled'
    };

    props.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
  }

  componentDidMount() {
    this.onSortChanged();
  }

  render() {
    let menu = null;
    if (this.props.menuIcon) {
      menu = <div className="customHeaderMenuButton">
        <img className="h-4 w-4 m-4" src={this.props.menuIcon} alt="." />
      </div>;
    }

    let sort = null;
    if (this.props.enableSorting) {
      console.log(this.props.enableSorting)
      sort =
        <div className="flex ml-2">
          <div onClick={this.onSortRequested.bind(this, 'asc')} className={`customSortDownLabel ${this.state.ascSort}`}>
            <i className="icon-height icon-width ag-icon ag-icon-desc"></i>
          </div>
          <div onClick={this.onSortRequested.bind(this, 'desc')} className={`customSortUpLabel ${this.state.descSort}`}>
            <i className="icon-height icon-width ag-icon ag-icon-asc"></i>
          </div>
          {/* <div onClick={this.onSortRequested.bind(this, '')} className={`customSortRemoveLabel ${this.state.noSort}`}>
            <i className="fa fa-times"></i>
          </div> */}
        </div>;
    }

    return (
      <div className="flex">
        {menu}
        <div className="customHeaderLabel">{this.props.displayName}</div>
        {sort}
      </div>
    );
  }

  onMenuClicked() {
    this.props.showColumnMenu(this.menuButton);
  }

  onSortChanged() {
    this.setState({
      ascSort: this.props.column.isSortAscending() ? 'enabled' : 'disabled',
      descSort: this.props.column.isSortDescending() ? 'enabled' : 'disabled',
      noSort: !this.props.column.isSortAscending() && !this.props.column.isSortDescending() ? 'enabled' : 'disabled'
    });
  }

  onMenuClick() {
    this.props.showColumnMenu(this.menuButton);
  }

  onSortRequested(order, event) {
    this.props.setSort(order, event.shiftKey);
  }
}