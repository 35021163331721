import React, { Component } from "react";
import "./login.scss";
import Icon from "static/images/buildings.png";
export default class login extends Component {
  render() {
    return (
      <div className="component-login bg-bg h-screen flex-center py-24 px-24">
        <div className="flex form-container shadow-lg h-full bg-white w-full rounded-lg overflow-hidden">
          <div
            className="relative left-container w-1/2 h-full "
            style={{ backgroundImage: "url(" + Icon + ")" }}
          >
            <div className="overlay p-6 font-bold flex-between  flex-col">
              <div className="text-xl self-start w-2/5 font-fmedium title">
                United Information Center
              </div>
              <div className="bottom-text self-start font-fmedium tracking-wide leading-normal">
                Track the past, present and future of companies worldwide
              </div>
            </div>
          </div>
          <div className="right-container flex justify-center flex-col px-16 w-1/2">
            <div className="form-container">
              <div className="text-4xl font-fbold mb-4">Hello There!</div>
              <div className="text-body-text mb-16">Sign In with us</div>
              <input
                className="theme-input bg-bg w-full px-4 mb-8"
                type="text"
                placeholder="Enter username"
              />
              <input
                className="theme-input bg-bg w-full px-4 mb-3"
                type="password"
                placeholder="Enter password"
              />
              <div className="text-sub-heading text-sm cursor-pointer ">
                Forgot password?
              </div>
              <button
                className="theme-btn px-16 mt-24 py-2"
                onClick={() => {
                  this.props.history.push("/dashboard");
                }}
              >
                SIGN IN
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
