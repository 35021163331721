import React, { Component } from "react";
import mapUtils from "utils/mapUtils.js";
import "./map.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as mapActions from "store/map/actions";
import { withRouter } from "react-router-dom";
import { setTimeout } from "timers";
import GccFilter from "components/common/gccFilter/gccFilter";
export class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapData: [],
      currentLevel: "Country",
      mapInstance: null,
      currentViewMarkers: [],
      gccData: null,
      currentLevelCount: 0,
      prevCountry: null,
      prevCountryCode: null,
      currentLatLngBounds: null,
      prevLatLngBounds: null,
      currentLocName: null,
      prevLevel: null,
      currentZoomLevel: 4.5,
      showGCCFilter: 0,
      mapFilterItem: Object.assign([], props.gccFilter.Data),
      modifiedMapFilterItem: props.gccFilter.modifiedData
    };
  }
  // life cycle methods.......................................................................
  componentDidMount() {
    let mapInstance = mapUtils.initialiseMap(
      document.getElementById("map"),
      13,
      { lat: 0, lng: 0 },
      true
    );
    window.google.maps.event.addListenerOnce(mapInstance, "tilesloaded", () => {
      if (this.props.mapComponentData) {
        this.setState({ ...this.props.mapComponentData, mapInstance }, () => {
          if (this.props.mapComponentData.currentLevel == "Country")
            this.backToPreviousView("All Country");
          else if (this.props.mapComponentData.currentLevel == "Region") {
            this.backToPreviousView("Country");
          } else {
            this.backToPreviousView("Region");
          }
        });
      } else {
        this.setState({ mapInstance }, () => {
          this.props.handleGccAlertBool(0);
          this.props.requestGetCountryLevelData({
            filters: this.props.gccFilter.modifiedData
              ? [this.props.gccFilter.modifiedData]
              : [],
            isFilter: this.props.gccFilter.modifiedData ? true : false
          });
        });
      }
    });
  }
  componentWillUnmount() {
    this.handleClearAllMarker();
  }
  componentWillReceiveProps(props) {
    if (props.gccData) {
      this.setState({ gccData: props.gccData });
    }
    if (
      props.mapData &&
      props.mapData.length &&
      this.state.mapInstance !== null &&
      !props.globalRequestCycleStatus.isProcessing
    ) {
      let markerBounds = [];
      let currentMarkerInstance = [];
      this.handleClearAllMarker();
      for (let i = 0; i < props.mapData.length; i++) {
        let markerLabel = this.handleLabelName(props.mapData[i]);
        let markerInstance = mapUtils.createMarker(
          props.mapData[i],
          this.state.mapInstance,
          this.handleMarkerClick,
          markerLabel
        );
        currentMarkerInstance.push(markerInstance);
        this.handleAddOperationOnPopup(
          markerInstance,
          props.mapData[i].industryList,
          this.state.mapInstance,
          props.mapData[i]
        );
        markerBounds.push([
          props.mapData[i].latitude,
          props.mapData[i].longitude
        ]);
      }
      this.setState({ currentViewMarkers: currentMarkerInstance });
      if (this.state.currentLevel === "Country")
        mapUtils.fitBounds(this.state.mapInstance, markerBounds);
    } else if (props.mapData && props.mapData.length === 0) {
      this.handleClearAllMarker();
    }
  }
  //function to find the markerLabelName.........................................
  handleLabelName = Detail => {
    if (this.state.currentLevel === "Country") {
      return Detail.countryName;
    } else if (this.state.currentLevel === "Region") {
      return Detail.stateName;
    } else {
      return Detail.cityName;
    }
  };
  //function called when marker is clicked...................................
  handleMarkerClick = markerDetail => {
    if (this.state.currentLevelCount <= 1) {
      this.handleClearAllMarker();
      if (this.state.currentLevelCount === 0) {
        let filters = [
          {
            filterName: "Country",
            subFilters: "includes",
            dependentFilter: [markerDetail.countryName]
          }
        ];
        if (this.state.modifiedMapFilterItem) {
          filters.push(this.state.modifiedMapFilterItem);
        }
        this.props.requestGetStateLevelData({
          filters: filters,
          isFilter: this.state.mapFilterItem.length ? true : false
        });
        this.setState(
          prevState => ({
            currentLevelCount: prevState.currentLevelCount + 1,
            currentLevel: "Region",
            prevLevel: "Country",
            prevCountry: markerDetail.countryName,
            prevCountryCode: markerDetail.countryCode,
            currentLocName: markerDetail.countryName,
            currentLatLngBounds: {
              latitude: markerDetail.latitude,
              longitude: markerDetail.longitude
            },
            prevLatLngBounds: {
              latitude: markerDetail.latitude,
              longitude: markerDetail.longitude
            }
          }),
          function() {
            this.saveMapState();
            mapUtils.moveToLocation(
              this.state.mapInstance,
              this.state.currentLatLngBounds.latitude,
              this.state.currentLatLngBounds.longitude
            );
            mapUtils.zoomTo(
              this.state.mapInstance,
              this.state.currentZoomLevel
            );
          }
        );
      } else if (this.state.currentLevelCount === 1) {
        let filters = [
          {
            filterName: "Region",
            subFilters: "includes",
            dependentFilter: [markerDetail.regionName]
          },
          {
            filterName: "Country",
            subFilters: "includes",
            dependentFilter: [markerDetail.countryName]
          }
        ];
        if (this.state.modifiedMapFilterItem) {
          filters.push(this.state.modifiedMapFilterItem);
        }
        this.props.requestGetCityLevelData({
          filters: filters,
          isFilter: this.state.mapFilterItem.length ? true : false
        });
        this.setState(
          prevState => ({
            currentLevelCount: prevState.currentLevelCount + 1,
            currentLevel: "City",
            prevLevel: "Region",
            currentZoomLevel: prevState.currentZoomLevel + 1.5,
            currentLocName: markerDetail.regionName,
            prevLatLngBounds: prevState.currentLatLngBounds,
            currentLatLngBounds: {
              latitude: markerDetail.latitude,
              longitude: markerDetail.longitude
            }
          }),
          function() {
            this.saveMapState();
            mapUtils.moveToLocation(
              this.state.mapInstance,
              this.state.currentLatLngBounds.latitude,
              this.state.currentLatLngBounds.longitude
            );
            mapUtils.zoomTo(
              this.state.mapInstance,
              this.state.currentZoomLevel
            );
          }
        );
      }
    } else {
      let state = [
        {
          filterName: "Region",
          selectedSubFilter: { value: "includes", label: "includes" },
          selected: true,
          selectedDependentFilter: [
            { label: markerDetail.regionName, value: markerDetail.regionName }
          ],
          filterHeader: "Basic Info",
          display: "MultiSelect",
          dependentFilter: "id=8",
          asynchronous: true,
          subFilters: [
            { label: "includes", value: "includes" },
            { label: "does not include", value: "does not include" }
          ]
        },
        {
          filterName: "Country",
          selectedSubFilter: { value: "includes", label: "includes" },
          selected: true,
          selectedDependentFilter: [
            { label: markerDetail.countryName, value: markerDetail.countryName }
          ],
          filterHeader: "Basic Info",
          display: "MultiSelect",
          dependentFilter: "id=4",
          asynchronous: true,
          subFilters: [
            { label: "includes", value: "includes" },
            { label: "does not include", value: "does not include" }
          ]
        },
        {
          filterName: "City",
          selectedSubFilter: { value: "includes", label: "includes" },
          selected: true,
          selectedDependentFilter: [
            { label: markerDetail.cityName, value: markerDetail.cityName }
          ],
          filterHeader: "Basic Info",
          display: "MultiSelect",
          dependentFilter: "id=7",
          asynchronous: true,
          subFilters: [
            { label: "includes", value: "includes" },
            { label: "does not include", value: "does not include" }
          ]
        }
      ];
      this.props.setFilterData(state);
      // if (this.state.mapFilterItem.length) {
      //   state.push(this.state.mapFilterItem[0]);
      // }
      this.saveMapState();
      this.props.history.push("/dashboard/table");
    }
  };
  //clear all markers on the current View.....................................
  handleClearAllMarker = () => {
    this.state.currentViewMarkers.forEach((markerItem, index) => {
      mapUtils.clearMarker(this.state.mapInstance, markerItem);
    });
    this.setState({ currentViewMarkers: [] });
  };
  //function to handle the gcc filter bool.......................................................
  handleGccFilterBool = val => {
    this.setState({ showGCCFilter: val });
  };
  //map filter item ..........................................................................
  mapAppliedFilter = (operation, item) => {
    let modifiedItem = null;
    if (item.length) {
      let modifiedData = {
        filterName: item[0].filterName,
        subFilters: item[0].selectedSubFilter.value,
        dependentFilter: item[0].selectedDependentFilter.map((data, index) => {
          return data.value;
        })
      };
      modifiedItem = modifiedData;
    }
    this.props.setGccFilterData({ Data: item, modifiedData: modifiedItem });
    this.setState(
      { mapFilterItem: item, modifiedMapFilterItem: modifiedItem },
      () => {
        if (this.state.currentLevel === "Country") {
          this.backToPreviousView("All Country");
        } else if (this.state.currentLevel === "Region") {
          this.backToPreviousView("Country");
        } else if (this.state.currentLevel === "City") {
          this.backToPreviousView("Region");
        }
      }
    );
    this.handleGccFilterBool();
  };
  //function to handle the back button ...............................................
  backToPreviousView = type => {
    switch (type) {
      case "All Country": {
        this.setState(
          {
            currentLevel: "Country",
            currentLevelCount: 0,
            prevCountry: null,
            prevCountryCode: null,
            currentLatLngBounds: null,
            prevLatLngBounds: null,
            currentLocName: null,
            prevLevel: null,
            currentZoomLevel: 4.5
          },
          () => {
            this.saveMapState();
            let filters = [];
            if (this.state.modifiedMapFilterItem) {
              filters.push(this.state.modifiedMapFilterItem);
            }
            this.props.requestGetCountryLevelData({
              filters: filters,
              isFilter: this.state.mapFilterItem.length ? true : false
            });
          }
        );
        break;
      }
      case "Country": {
        this.setState(
          {
            currentLevel: "Region",
            currentLevelCount: 1,
            prevLevel: "Country",
            currentLocName: this.state.prevCountry,
            currentZoomLevel: 4.5,
            currentLatLngBounds: this.state.prevLatLngBounds,
            prevLatLngBounds: this.state.prevLatLngBounds
          },
          () => {
            this.saveMapState();
            mapUtils.moveToLocation(
              this.state.mapInstance,
              this.state.currentLatLngBounds.latitude,
              this.state.currentLatLngBounds.longitude
            );
            mapUtils.zoomTo(
              this.state.mapInstance,
              this.state.currentZoomLevel
            );
            let filters = [
              {
                filterName: "Country",
                subFilters: "includes",
                dependentFilter: [this.state.prevCountry]
              }
            ];
            if (this.state.modifiedMapFilterItem) {
              filters.push(this.state.modifiedMapFilterItem);
            }
            this.props.requestGetStateLevelData({
              filters: filters,
              isFilter: this.state.mapFilterItem.length ? true : false
            });
          }
        );
        break;
      }
      case "Region": {
        this.saveMapState();
        mapUtils.moveToLocation(
          this.state.mapInstance,
          this.state.currentLatLngBounds.latitude,
          this.state.currentLatLngBounds.longitude
        );
        mapUtils.zoomTo(this.state.mapInstance, this.state.currentZoomLevel);
        let filters = [
          {
            filterName: "Country",
            subFilters: "includes",
            dependentFilter: [this.state.prevCountry]
          },
          {
            filterName: "Region",
            subFilters: "includes",
            dependentFilter: [this.state.currentLocName]
          }
        ];
        if (this.state.modifiedMapFilterItem) {
          filters.push(this.state.modifiedMapFilterItem);
        }
        this.props.requestGetCityLevelData({
          filters: filters,
          isFilter: this.state.mapFilterItem.length ? true : false
        });
        break;
      }
    }
  };
  //add popup on marker........................................................
  handleAddOperationOnPopup = (
    markerInstance,
    markerDetail,
    mapInstance,
    locObj
  ) => {
    let popupContent = document.createElement("div");
    popupContent.className = "content-popup-box ";
    //loc detail on popup.....................................................
    let locDetailOnPopupElement = document.createElement("div");
    locDetailOnPopupElement.className =
      "loc-type-detail font-fmedium py-2 text-hero flex justify-between items-center";
    let locTypeElement = document.createElement("div");
    locTypeElement.className =
      "loc-type mt-1 text-xxs bg-divider px-2 py-1 text-black inline-block rounded-full";
    locTypeElement.appendChild(
      document.createTextNode(this.state.currentLevel)
    );
    let locTypeValueElement = document.createElement("div");
    locTypeValueElement.className = "loc-type-value ";
    locTypeValueElement.appendChild(
      document.createTextNode(
        locObj[this.state.currentLevel.toLowerCase() + "Name"]
      )
    );
    locDetailOnPopupElement.appendChild(locTypeValueElement);
    locDetailOnPopupElement.appendChild(locTypeElement);

    popupContent.appendChild(locDetailOnPopupElement);
    //construction of sector item...........................................
    for (let i = 0; i < markerDetail.length; i++) {
      let sectorItem = document.createElement("div");
      sectorItem.className = "sector-item font-fmedium relative pr-6 text-xxs";
      let companyElement = document.createElement("div");
      let companyTextNode = document.createTextNode(
        markerDetail[i]["companyCount"]
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      );
      companyElement.appendChild(companyTextNode);
      companyElement.className = "company-count ";
      let industryElement = document.createElement("div");
      let industryTextNode = document.createTextNode(
        markerDetail[i]["industryName"]
      );
      industryElement.appendChild(industryTextNode);
      industryElement.className = "industry-name";
      sectorItem.appendChild(companyElement);
      sectorItem.appendChild(industryElement);
      if (markerDetail[i]["gccAlert"] === "Yes") {
        let bellElement = document.createElement("div");
        bellElement.className =
          "h-4 w-4 rounded-full cursor-pointer flex justify-center items-center absolute pin-t pin-r text-white text-xxs bg-notify";
        let bellIconElement = document.createElement("i");
        bellIconElement.className = "fa fa-bell";
        sectorItem.onclick = () => {
          this.props.handleGccAlertItem(
            markerDetail[i],
            locObj,
            "Map",
            this.state.modifiedMapFilterItem
              ? [this.state.modifiedMapFilterItem]
              : []
          );
        };
        bellElement.appendChild(bellIconElement);
        sectorItem.appendChild(bellElement);
      }
      popupContent.appendChild(sectorItem);
    }
    mapUtils.addPopupOnMarker(markerInstance, popupContent, mapInstance);
  };
  //function to save the map Stat...............................................
  saveMapState = () => {
    let clonedState = Object.assign({}, this.state);
    delete clonedState["mapInstance"];
    delete clonedState["mapData"];
    delete clonedState["mapFilterItem"];
    delete clonedState["modifiedMapFilterItem"];
    this.props.setMapComponentData(clonedState);
  };
  // render function................................................................
  render() {
    return (
      <div>
        <div className="m-8 fixed pin-r" style={{ "z-index": "10000" }}>
          {/* <button
            className="square-btn  cursor-pointer"
            onClick={this.handleGccFilterBool}
          >
            <i className="fa fa-filter" />
          </button> */}
          {this.state.showGCCFilter ? (
            <div
              className="bg-white filter-container overflow-hidden rounded shadow  absolute w-full z-10 pin-r"
              style={{ height: "330px" }}
            >
              <GccFilter
                FilterData={this.state.mapFilterItem}
                handleGccFilterBool={this.handleGccFilterBool}
                AppliedFilter={this.mapAppliedFilter}
              />
            </div>
          ) : null}
        </div>
        <div id="map" className="component-map" />
        {this.state.currentLevelCount > 0 ? (
          <div className="back-btn shadow text-xs font-fmedium font-normal tracking-wide">
            {this.state.currentLevel === "Region" ||
            this.state.currentLevel === "City" ? (
              <div
                className="chevron-btn first bg-white text-divider"
                onClick={() => {
                  this.backToPreviousView("All Country");
                }}
              >
                All Countries
              </div>
            ) : null}
            {this.state.prevLevel === "Region" ||
            this.state.prevLevel === "City" ? (
              <div
                className="chevron-btn bg-white relative  text-divider"
                onClick={() => {
                  this.backToPreviousView("Country");
                }}
              >
                <div className="absolute pin-l pin-t h-full -ml-1 text-xxs flex-center text-divider">
                  <i className="fa fa-chevron-right" />
                  <i className="fa fa-chevron-right" />
                </div>
                {this.state.prevCountry}
              </div>
            ) : null}
            {this.state.currentLevel === "City" ||
            this.state.currentLevel === "Region" ? (
              <div className="chevron-btn active text-white right-arrow">
                {this.state.currentLocName &&
                this.state.currentLocName.length > 30
                  ? this.state.currentLocName.slice(0, 10) + "..."
                  : this.state.currentLocName}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}
export default withRouter(
  connect(
    ({ map, globalRequest }) => ({ ...map, ...globalRequest }),
    dispatch => bindActionCreators({ ...mapActions }, dispatch)
  )(Map)
);
