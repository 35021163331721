import { initialState } from "./states";

export const globalRequest = (state = initialState, action) => {
  //console.log(action.type);
  switch (action.type) {
    case "SET_GLOBAL_REQUEST": {
      return Object.assign({}, state, {
        globalRequestCycleStatus: action.payload
      });
    }
    case "SET_BACKDROP_ACTIVE": {
      return Object.assign({}, state, {
        backdropActive: action.payload
      });
    }
    case "SET_MODAL_COMPONENT": {
      return Object.assign({}, state, {
        modalComponent: action.payload
      });
    }
    case "SET_OVERFLOW": {
      return Object.assign({}, state, {
        isOverflow: action.payload
      });
    }
    default:
      return state;
  }
};
