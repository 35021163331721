import { initialState } from "./states";

export const search = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SEARCH_RESULT_LIST": {
      return Object.assign({}, state, {
        searchResultList: action.data
      });
    }
    case "SET_REQUEST_CYCLE_STATUS": {
      return Object.assign({}, state, {
        requestCycleStatus: action.data
      });
    }
    default:
      return state;
  }
};
