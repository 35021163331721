import React, { Component } from "react";
import "./companyNews.scss";
import EmptyContainer from "static/images/empty-result.png";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
export default class CompanyAlerts extends Component {
  constructor(props) {
    super(props);
    this.state = { "companyName": "-" }
  }
  componentWillReceiveProps(props) {
    console.log(props)
    // if (props.gccData && props.gccData) {
    //   this.setState()
    // }
  }
  handleGccTitleClick = obj => {
    window.open(obj.url);
  };
  render() {
    return (
      <div style={{ zIndex: this.props.globalRequest.isProcessing ? "0" : "1000" }} className={this.props.containerClass + " overflow-hidden rounded shadow absolute rounded bg-white"}>
        <Tabs>
          <TabList>
            <Tab>Alerts</Tab>
            <Tab>All Alerts</Tab>
            <div
              className="close-btn cursor-pointer hover:text-hero float-right p-2"
              onClick={() => this.props.handleGccAlertBool(0)}
            >
              <i className="fa fa-times" />
            </div>
          </TabList>
          <TabPanel>
            <div className="text-gcc overflow-auto mt-3 px-3 pb-3 font-fmedium" style={{ height: "400px" }}>
              {this.props.gccData.rows && this.props.gccData.rows.length ? (
                this.props.gccData.rows.map((row, index) => {
                  return (
                    <div className="gcc-news-section mt-1 py-2 px-3 bg-bg border border-hero-light">
                      <div className="heading-section">
                        <div className="name-title-section text-xs font-fmedium flex-between">
                          <div className="name-section alert-company-name text-xs text-white px-3 py-2 rounded-full">
                            {row.name}
                          </div>
                          <div className="date-section">{row.date}</div>
                        </div>
                        <div className="name-section mt-3 text-xs">
                          {row.signal}
                        </div>
                        <div
                          className="title-section text-sm mb-3 mt-3 cursor-pointer "
                          onClick={() => this.handleGccTitleClick(row)}
                        >
                          {row.title}
                        </div>
                      </div>
                      <div className="snippet-section opacity-50 text-xs">
                        {row.snippet}
                      </div>
                    </div>
                  );
                })
              ) : (
                  <div className="empty-container flex justify-center p-10 items-center">
                    <img
                      className="img-responsive"
                      style={{ height: "50px" }}
                      src={EmptyContainer}
                      alt="No Data"
                    />
                  </div>
                )}
            </div>
          </TabPanel>
          <TabPanel>
            <div className="text-gcc overflow-auto mt-3 px-3 pb-3 font-fmedium" style={{ height: "400px" }}>
              {this.props.gccAllAlertData.rows && this.props.gccAllAlertData.rows.length ? (
                this.props.gccAllAlertData.rows.map((row, index) => {
                  return (
                    <div className="gcc-news-section mt-1 py-2 px-3 bg-bg border border-hero-light">
                      <div className="heading-section">
                        <div className="name-title-section text-xs font-fmedium flex-between">
                          <div className="name-section text-xs">
                            {row.signal}
                          </div>
                          <div className="date-section">{row.date}</div>
                        </div>
                        {/* <div className="name-section mt-3 text-xs">
                          {row.signal}
                        </div> */}
                        <div
                          className="title-section text-sm mb-3 mt-3 cursor-pointer "
                          onClick={() => this.handleGccTitleClick(row)}
                        >
                          {row.title}
                        </div>
                      </div>
                      <div className="snippet-section opacity-50 text-xs">
                        {row.snippet}
                      </div>
                    </div>
                  );
                })
              ) : (
                  <div className="empty-container flex justify-center p-10 items-center">
                    <img
                      className="img-responsive"
                      style={{ height: "50px" }}
                      src={EmptyContainer}
                      alt="No Data"
                    />
                  </div>
                )}
            </div>
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}
